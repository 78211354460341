import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space p-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col sm={12} className="my-auto py-5">
              <h2 className=" section-title mx-auto text-uppercase text-center">
                FAQ
              </h2>
              <h3>
                Is there a fee to participate in the KSA-CISO POWERLIST 2020 ?
              </h3>
              <p>No. There is no fees.</p>
              <br />
              <br />
              <h3>What should I/we include in our submission?</h3>
              <p>
                We have provided a small questionnaire for the parameters, we
                encourage participants to be as specific as possible when
                highlighting expertise and work. The statement must provide
                tangible examples to support the entry. Good entries tend to
                focus on measurable results. In short, facts and figures are
                always welcome as they can be easily measured. Attachments to
                support the claims and numbers should be added.
              </p>
              <br />
              <br />
              <h3>How will I be judged?</h3>
              <p>
                KSA-CISO POWERLIST 2020 will be judged by the editorial team of
                Khaleej Times along with an Advisory Committee that will consist
                of heads of finance related associations, government officials,
                journalists and more. They will be deciding on basis of the
                nominations received on the FutureSec website.
              </p>
              <br />
              <br />
              <h3>
                Where did you get my information from/why have I been contacted?
              </h3>
              <p>
                If we have contacted you regarding the KSA-CISO POWERLIST 2020
                then that is because you have been nominated via our website.
              </p>
              <br />
              <br />
              <h3>Is there a ceremony?</h3>
              <p>
                Yes, the KSA-CISO POWERLIST Announcement 2022 will take place,
                virtually, in July along with the FutureSec 2022.
              </p>
              <br />
              <br />
              <h3>Can I submit more than one entry?</h3>
              <p>
                Yes, if you are eligible for more than one category, you may
                submit the entry.
              </p>
              <br />
              <br />
              <h3>How can I find out more about the process?</h3>
              <p>
                If you would like any more information, please feel free to
                contact our marketing associate, Priyadarshini Rampuria on{" "}
                <a href="mailto:priya@khaleejtimes.com">
                  priya@khaleejtimes.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
