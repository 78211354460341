import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              Sick leave applications of employees will now be approved using
              automated systems, the Abu Dhabi City Municipality has announced.
            </p>
            <br />
            <br />
            <p>
              The robotic programme, powered by artificial intelligence (AI),
              will also be used to speed up services, enhance financial services
              and registration of real estate contracts.
            </p>
            <br />
            <br />
            <p>
              The municipality launched the robotic process automation (RPA) to
              approve sick leaves for employees in cooperation with the
              Department of Health through the human resources management system
              (HRMS)."The robot carries out the task within a few minutes - a
              procedure previously executed by three employees in a much longer
              timeframe. The new technologies help the civic body save 260 work
              hours per month," the authority said in a statement.
            </p>
            <br />
            <br />
            <p>
              "The robots will also assist financial affairs services such as
              the settlement of accounts in the finance and treasury by checking
              the bank statement for the online payment and paying machines. The
              robot carries out the financial settlement tasks in just minutes,
              a procedure that was previously handled by seven employees and
              took at least nine working hours. The new technology saves more
              than 207 hours of work per month," added the municipality.
            </p>
            <br />
            <br />
            <p>
              The AI technology is also applied in the registration of real
              estate contracts at the municipality. It links between the Smart
              Hub and the land system to create mortgage applications and
              registering the first time sale in the land system.
            </p>
            <br />
            <br />
            <p>
              "The robot automatically opens up the transaction from the digital
              platform (Smart Hub) and transfers it to the land system. It then
              updates its status on the municipality's digital platform upon
              completion, which increases the speed of response, and customer
              satisfaction without any human intervention," said the
              municipality adding that the new technology has recently been
              tried and tested, providing more than 200 hours of work per month.
            </p>
            <br />
            <br />
            <p>
              The municipality confirmed that by adopting this digital
              programme, it would be one of the first pioneering institutions to
              implement the RPA system at the state level.
            </p>
            <br />
            <br />
            <p>
              The municipality had earlier launched the pilot phase of a
              five-year project for Smart Cities and Artificial Intelligence
              (AI), called the Zayed Smart City project, to validate key use
              cases and its viability. The city-wide project is designed to
              digitally transform the environmental, social, and financial
              aspects of urban life to improve the lives of the UAE capital
              city's citizens and visitors.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
