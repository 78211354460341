import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>
              In conversation with Dr.-Ing. Matthias Schindler, Head of
              Artificial Intelligence, Production System BMW Group
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              Dr. Schindler is heading all the Artificial Intelligence, AI,
              innovation activities in the BMW production system which comprises
              31 locations in 15 countries. Located in the research and
              development center in Munich, Dr. Schindler is responsible for the
              integration of innovative AI solutions in manufacturing.
            </p>
            <br />
            <br />
            <h3>What are your top 3 predictions for AI?</h3>
            <p>
              <ul>
                <li>
                  There will be various new types of applications besides
                  vision: Currently, we see many AI applications in the field of
                  image processing. These AI systems run very robustly due to a
                  wide range of architectures and frameworks which support the
                  creation e. g. of a neural network for a specific task. First
                  applications besides vision systems can already be found in
                  the industry. In the future, I expect more robust
                  deep-learning-based computer vision to come, e. g.
                  applications of 3-D photos. In addition, I see applications in
                  production, such as anomaly detection in (semi-)structured
                  mass data, which are recorded during production processes. I
                  also expect applications such as multilingual chatbots for
                  efficient searches and automatic tagging of texts such as
                  manuals and instructions.
                </li>
                <li>
                  Zero effort for labeling: AI typically requires training data
                  and labels. Nowadays, most time consuming during the training
                  process is to collect and label this data. I expect a massive
                  acceleration of the modeling process due to the automatic
                  generation of realistic pictures. Thus, the image
                  synthetization will reduce the effort for the data aggregation
                  and labeling to a minimum or even zero. Here, the rendering of
                  pictures can be one factor, additionally, AI also supports the
                  image generation.
                </li>
                <li>
                  Democratization of AI: It is still rather complicated to setup
                  an AI system. On the one hand the hardware setup typically
                  demands high-performance special equipment. On the other hand,
                  expertise is needed to setup the software part of it –
                  regarding drivers, packages, and selecting the correct
                  framework. Soon, we will see significant improvement in
                  user-friendliness at end-user level. This means, users with
                  little or no expertise in IT, in algorithms or in Machine
                  Learning will be put in the position to setup their own AI
                  system without the support of a data scientist. This
                  development is accelerated by application programming
                  interfaces that are available for more and more AI frameworks.
                  Furthermore, solutions for very easy labeling and the
                  automatic hyperparameter tuning are arising. A future
                  end-to-end self-service for AI enables the leveraging of AI
                  across the value chain at a light speed pace. Additionally,
                  the scaling of cutting edge, proven AI applications will
                  outperform other technologies as those are stuck in a
                  discussion about the return on investment.{" "}
                </li>
              </ul>
            </p>
            <br />
            <br />
            <h3>
              If you could solve any global problem in the world with AI, what
              would it be?
            </h3>
            <p>
              The biggest challenge AI itself must overcome is the reservations
              people have about it, due to a lack of experience. On the one
              hand, it is a rather small group of experts and scientists that
              has access to AI. On the other hand, many people still do not know
              what they can really expect from AI and where AI can improve their
              daily lives. The mission of the BMW Group is to offer AI as a
              self-service, especially for employees in manufacturing. The
              shortage of data scientists leads into the same direction – it is
              unrealistic to retrain shop floor employees into programmers, that
              would be misleading and would take too much time. In my mind, AI
              will only scale with an approach of democratization: If algorithms
              become systems that everybody can use – without writing a single
              line of code or thinking about the setup of the system or drivers,
              AI systems will be applied on a broad base. Furthermore, these
              should have intuitive graphical user interfaces. The
              democratization of AI innovations will help people to better
              understand AI and thus find out for themselves where personal
              benefits are.
            </p>
            <br />
            <br />
            <h3>
              While the power of data analytics is beyond doubt, what do you
              think are the crucial challenges in the implementation of
              automation and AI?
            </h3>
            <p>
              When thinking about AI in manufacturing, it is essential to
              clearly communicate the capabilities of AI. We are already seeing
              applications where Artificial Intelligence has far exceeded human
              parity. Nevertheless, AI is not free of charge at present.
              Therefore, I focus on presenting the advantages and the
              performance of AI as well as talking about the process, how an AI
              solution can be established and what effort must be invested. This
              also includes a realistic expectation management. Every production
              expert finds use cases where an innovative system might improve
              his work or accelerate processes. But, to give an example, we
              would not setup an AI which takes only one 360-degree picture of
              the car and then unspecifically tells us if there is anything
              wrong. Another example is the inspection of painted surfaces: At
              reasonable costs, you cannot set up an AI, which detects all
              possible surface damages. AI should be considered one tool out of
              many within a toolbox that also includes the methods of lean
              production. Clear communication is needed of what AI can do, how
              robustly a solution can run, and what kind of effort needs to be
              invested. <br />
              The second important thing is the protection and commissioning of
              AI solutions. Depending on the characteristics of a given task,
              there must be a checklist for critical points and a precise
              description of the minimum required test procedures. The BMW Group
              is developing a holistic methodological framework that supports
              the planners in the testing process of AI. At the BMW Group, every
              AI use case has to undergo an assessment under realistic
              conditions before integration into series production.
            </p>
            <br />
            <br />
            <h3>
              What’s the “coolest”/ most “wow-factor" thing you’ve seen so far
              in with the use of AI in the sector you work in?
            </h3>
            <p>
              In my mind, the most exciting AI application is a chatbot to
              improve production processes e. g. when ramping up production.
              Without giving too many details, an internal chatbot solution for
              BMW production employees helps them to identify the correct rework
              strategy in real-time. In the past, this process took a lot of
              time only for searching the corresponding documents – the assembly
              instruction, the rework specification, the circuit diagram, and so
              on. The innovative text-based chatbot now eliminates this
              non-productive time. This application is still under development
              at the IT Innovation Lab. Soon, we will see even improved
              functionality and present more details.
            </p>
            <br />
            <br />
            <h3>
              What are some of the noticeable achievements of your organization
              when it comes to Data Analytics and Artificial Intelligence?
            </h3>
            <p>
              Given its enormous potential, we made Smart Data Analytics one of
              our four digitalization clusters of the predevelopment in
              production as early as 2017. We have seen many success stories:
              Starting with a methodology for prioritization where we identify
              the most urgent need for action, we have invented our own sensor
              kit for retrofitting existing equipment. Further solutions leading
              to a substantial rise in system availability have been
              successfully developed and rolled out.
            </p>
            <br />
            <p>
              When it comes to AI in manufacturing, we currently focus on
              detection tasks. One of our first 100% robust applications was the
              type plate recognition: In the past, all the type plates had to be
              checked by an employee – a repetitive and non-value-adding task.
              With the help of Deep Learning, we created a solution to check all
              the type plates – under arbitrary environmental conditions, while
              the cars are moving. In the meantime, we have implemented
              countless similar AI solution in assembly shops all over the world
              to detect if the right component has been assembled correctly.
              This kind of support for inspection tasks is very helpful for us
              as individualization is a core principle of our business model as
              a premium manufacturer: the BMW 3 Series, for example, allow for
              up to one billion combinations. AI also helps us to realize
              process improvements, for example in the paint shop: First, we
              aggregated process data (like atmospheric pressure, temperature,
              humidity, flow rate, …). We installed innovative dust sensors at
              specific points. AI now enables us to detect correlations between
              these process parameters and product quality. Based on these
              findings, we can improve the windows for process control values.
              It is the goal to optimize all the process parameters we can
              adjust.
            </p>
            <br />
            <p>
              In the body shop, AI based on videos can support maintenance
              specialists to identify the condition of welding robots:
              Deep-Learning-based object detection categorizes welding spots.
              With the help of this detection, we can determine whether a robot
              needs maintenance. Real-time detection helps us to minimize the
              rejection rate in the press shop: We implemented an AI solution
              which detects if formed sheet metal parts are ok. With an infrared
              camera installed in the pressing tool, the critical area is
              photographed. An AI categorizes if the part is ok, if there is
              dust or oil or if the part is actually torn. This AI has been
              tuned to be very quick as the cycle time is below 1.7 seconds. It
              replaces a conventional computer vision system which worked pixel
              based. In contrast to the AI solution, pixel-based systems may
              remove good parts they wrongly categorize as cracked. Now, the AI
              detects damages with 100% reliability in real-time.
            </p>
            <br />
            <br />
            <h3>
              What can delegates/attendees take away from your session at the
              Artelligence Forum 2020?
            </h3>
            <p>
              My session at the Artelligence Forum focuses on the Self-Service
              Approach for AI that the BMW Group is pursuing. Or mission is to
              offer AI to all our employees in production. This means, we will
              intentionally not upskill 50,000 production employees into data
              scientist, but we will simplify AI so that we will have 50,000
              potential AI users. At the BMW Group, technology serves people.
              This is why, we bring AI into manufacturing: Every production
              worker will be able to use AI systems, AI expands their toolbox
              and they can decide for themselves which innovative solution can
              best support them in a given task.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
