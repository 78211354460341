import React from "react";

import { Container, Row, Col, Card, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function News({ news }) {
  const shareUrl = "";
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <div className="overlay2 d-none d-xl-block d-lg-block " />
        <div className="overlayMedium d-none d-xl-none d-lg-none d-md-block" />
        <div className="overlayMobile d-xl-none d-lg-none d-md-none d-sm-block d-xs-block" />
        <Container>
          <Row>
            {news.map((n, index) => (
              <Col lg={4} className={"ml-auto mr-auto"} key={index}>
                <Card
                  className=""
                  style={{
                    borderRadius: 0,
                  }}
                >
                  <a href={`/media/industry-news/${n.link}`}>
                    <Container fluid>
                      <Row>
                        <Col
                          lg={8}
                          className="align-self-center"
                          style={{ padding: 0 }}
                        >
                          <img
                            src={require(`assets/news/${n.image}`)}
                            alt="meltwater"
                            width="100%"
                            style={{ borderRadius: 0 }}
                          />
                        </Col>
                        <Col
                          lg={4}
                          className="pt-4"
                          style={{
                            backgroundColor: "#1d1960",
                          }}
                        >
                          <h4
                            className="text-white"
                            style={{ margin: 0, padding: 0 }}
                          >
                            {n.title}
                          </h4>
                          <br />

                          <br />
                        </Col>
                        <Col
                          xs={3}
                          style={{
                            padding: "0.5rem",
                          }}
                        >
                          <FacebookShareButton
                            url={`${shareUrl}${n.link}`}
                            children={
                              <Button
                                color="neutral"
                                outline
                                style={{
                                  padding: "0rem",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#3b5998",
                                    display: "inline",
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "20px" }}
                                    className="fa fa-facebook-square float-left"
                                  />
                                </span>
                              </Button>
                            }
                          />
                        </Col>
                        <Col
                          xs={3}
                          style={{
                            padding: "0.5rem",
                          }}
                        >
                          <LinkedinShareButton
                            url={shareUrl}
                            children={
                              <Button
                                color="neutral"
                                outline
                                style={{
                                  padding: "0rem",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#0976b4",
                                    display: "inline",
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "20px" }}
                                    className="fa fa-linkedin-square float-left"
                                  />
                                </span>
                              </Button>
                            }
                          />
                        </Col>
                        <Col
                          xs={3}
                          style={{
                            padding: "0.5rem",
                          }}
                        >
                          <TwitterShareButton
                            url={shareUrl}
                            children={
                              <Button
                                color="neutral"
                                outline
                                style={{
                                  padding: "0rem",
                                }}
                              >
                                <span
                                  style={{
                                    color: "#55acee",
                                    display: "inline",
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "20px" }}
                                    className="fa fa-twitter-square float-left"
                                  />
                                </span>
                              </Button>
                            }
                          />
                        </Col>
                        <Col
                          xs={3}
                          style={{
                            padding: "0.5rem",
                          }}
                        >
                          <Button
                            className="btn"
                            color="primary"
                            size="sm"
                            href={`/media/industry-news/${n.link}`}
                            outline
                            style={{ minWidth: "100%" }}
                          >
                            More
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </a>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default News;
