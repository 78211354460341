import React from "react";

import { Container, Row, Col, Card, Button, CardImg } from "reactstrap";

import ScrollAnimation from "react-animate-on-scroll";

function WhoAttend() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space border-top border-bottom py-0 pb-3"
        data-parallax={true}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="align-self-center">
              <h2 className="text-left text-400 p-2 text-main">
                <br />
                Attend Saudi Arabia’s biggest gathering of cybersecurity experts
                hosting an extensive discussion on opportunities and
                innovation-driven information security market in KSA, as well as
                the trends across 4 major industry verticals.
                <br />
                <br /> The comprehensive two-day conference comprises of both
                inspirational and hands-on content, as well as a virtual
                exhibition of emerging cybersecurity technologies, with an
                opportunity interact with experts, from the comfort of your own
                home.
              </h2>
            </Col>
            <Col lg={6} className="align-self-center">
              <img
                src={require("assets/images/attend.jpg")}
                alt="attend"
                width="100%"
              />
            </Col>
            <Col lg={12}>
              <Container fluid>
                <Row className="justify-content-center pt-3">
                  {content2.map((data, index) => (
                    <Col lg={6} key={index} style={{}}>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOnce={true}
                        duration={0.5}
                      >
                        <Card
                          className="p-3"
                          style={{
                            boxShadow: "none",
                            borderTop: "3px solid #e00e28",
                          }}
                        >
                          <h3 className="text-700 pt-3">
                            <span className="text-success">
                              <i
                                class="fa fa-arrow-right"
                                aria-hidden="true"
                              ></i>
                            </span>{" "}
                            {data.title}
                          </h3>
                        </Card>
                      </ScrollAnimation>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container
          fluid
          style={{
            backgroundColor: "#e11328",
          }}
        >
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                WHY VIRTUAL?
              </h1>
            </Col>
          </Row>
        </Container>
        <Container className="py-4">
          <Row className="justify-content-center">
            <Col lg={12} className="text-700">
              <h3>
                While we know there's no substitute for getting together in
                person, we've built a unique opportunity for collaboration and
                learning. We’ve tried to create a balance between the experience
                of an in-person event with the flexibility of location
                independence with the best-in-class virtual platform to ensure
                that you still experience the same high quality and content rich
                event that we have always produced - this is more than your
                standard webinar!
              </h3>
              <br />
              <h2>
                THE VIRTUAL PLATFORM WILL PROVIDE AN IMMERSIVE ONLINE EXPERIENCE
                FOCUSING ON:
              </h2>
            </Col>
            {content3.map((data, index) => (
              <Col lg={3} xs={6} key={index} className="py-2">
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <Card className="p-3">
                    <CardImg
                      src={require(`assets/images/icons/${data.image}`)}
                      style={{ maxWidth: "100px" }}
                      className="mx-auto"
                    />
                    <hr />
                    <h3 className="text-700 pt-3">{data.title}</h3>
                  </Card>
                </ScrollAnimation>
              </Col>
            ))}

            <Col lg={12} className={"text-center"}>
              <Button
                href="/register"
                className="mt-4"
                color="primary"
                size="md"
              >
                REGISTER NOW
              </Button>
            </Col>
          </Row>
        </Container>
        <Container
          className="mt-3"
          fluid
          style={{
            backgroundColor: "#e11328",
          }}
        >
          <Row>
            <Col lg={12}>
              <h1 className="section-title text-center p-2 text-white">
                WHAT’S NEW FOR 2020?
              </h1>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="justify-content-center my-5">
            <Col lg={12} className="text-700">
              <h3>
                While we know there's no substitute for getting together in
                person, we've built a unique opportunity for collaboration and
                learning. We’ve tried to create a balance between the experience
                of an in-person event with the flexibility of location
                independence with the best-in-class virtual platform to ensure
                that you still experience the same high quality and content rich
                event that we have always produced - this is more than your
                standard webinar!
              </h3>
              <br />
              <h2>
                THE VIRTUAL PLATFORM WILL PROVIDE AN IMMERSIVE ONLINE EXPERIENCE
                FOCUSING ON:
              </h2>
            </Col>
            {content.map((data, index) => (
              <Col
                lg={6}
                xs={12}
                key={index}
                className="py-2 text-center border"
              >
                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOnce={true}
                  duration={0.5}
                >
                  <img
                    src={require(`assets/images/icons/${data.image}`)}
                    alt=""
                    width="100%"
                    style={{ maxWidth: "100px" }}
                  />
                  <h3 className="text-700 text-uppercase pt-3">{data.title}</h3>
                  <h3 className="text-400">{data.description}</h3>
                </ScrollAnimation>
              </Col>
            ))}
            <Col lg={12} className={"text-center"}>
              <Button
                href="/sponsor/download-brochure"
                className="mt-4"
                color="primary"
                size="md"
              >
                VIEW FULL AGENDA
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content2 = [
  {
    title: `EXPERIENCE Latest security technologies
    from 10+ global exhibitors. Evaluate live
    demos and discussions on the new security
    product breakthroughs and innovations.`,
  },
  {
    title: `RECOGNITION Awards giving an ideal platform
      for businesses to create brand awareness and
      position themselves as industry leaders.`,
  },
  {
    title: `CONFERENCE Witness powerful industry insights
      from experts, free to attend presentations,
      hands on workshop and live competitions.
      FutureSec brings together security titans &
      experts on one intensive agenda`,
  },
  {
    title: `WOMEN IN SECURITY Highlighting woman
      pioneers in security leadership roles with
      engaging panel discussions, debates and
      recognitions.`,
  },
  {
    title: `FREE LEARNING Let your team gain security
      awareness with 20+ hours of learning and
      discussion on 4 industry verticals: Banking,
      Gvoernment, Healthcare, & Energy`,
  },
  {
    title: `MEDIA organizations from local and regional
    channels joins FutureSec to cover latest stories
    on security implementations & scenes in KSA.
    Last year we received 500,000+ impressions
    on social media regionally.`,
  },
  {
    title: `NETWORKING with prequalified 200+ security
    professionals from KSA & MENA region
    belonging to 4 focus industries.`,
  },
];

const content3 = [
  {
    title:
      "Insightful content with an opportunity to presentations live and engage in Q&A’s",
    image: "3.png",
  },
  {
    title:
      "Virtual chat rooms where registrants can talk to one another about session topics and connect directly with speakers",
    image: "8.png",
  },
  {
    title:
      "Dedicated video conferencing with small groups of like-minded peers on carefully curated topics",
    image: "38.png",
  },
  {
    title:
      "Virtual partners booths where you can pick up all the information you need to make an informed decision and also speak with a representative to hear all they have to offer.",
    image: "4.png",
  },
];
const content = [
  {
    title: "CISO POWERLIST",
    image: "8.png",
    description:
      "Khaleej Times CISO Powerlist will recognize and celebrate the virtue of leadership skills, innovation and strategy of those who excel and exceed expectations when it comes to securing their organization's critical assets.",
  },

  {
    title: "VIRTUAL NETWORKING LOUNGE",
    image: "38.png",
    description:
      "The FutureSec Summit platform uses a match-making software to connect attendees at the event based on their preferences, providing an opportunity to easily expand your network.",
  },
];
export default WhoAttend;
