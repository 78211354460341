import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              The GCC retail sector was projected to grow at a Compound Annual
              Growth Rate of (CAGR) of four per cent from $253.2 billion in 2018
              to $275.4 billion in 2020, and then to $308 billion in 2023.
            </p>
            <br />
            <br />
            <p>
              Digital transformation is set to play a key role in the recovery
              of the GCC's retail sector, which has seen a dramatic shift during
              the Covid-19 pandemic as consumers in the region alter their
              shopping habits to adapt to the new reality.
            </p>
            <br />
            <br />
            <p>
              Highlighting the future of the sector, and the role that
              technology will play in shaping it, experts at a webinar organised
              by RetailME, pointed out that data, artificial intelligence,
              e-commerce, and robotics are expected to drive growth.
            </p>
            <br />
            <br />
            <p>
              According to a report by Alpen Capital, the GCC retail sector was
              projected to grow at a Compound Annual Growth Rate of (CAGR) of
              four per cent from $253.2 billion in 2018 to $275.4 billion in
              2020, and then to $308 billion in 2023. However, the months of
              lockdown resulting from governments across the GCC trying to
              contain the spread of the virus, has led to officials revising
              their earlier estimates. Faced with job losses and salary cuts,
              consumers quickly put off making spontaneous purchases, and
              spending only on their immediate needs.
            </p>
            <br />
            <br />
            <p>
              Ashish Panjabi, COO of Jacky's Retail &amp; Jacky's Business
              Solutions, noted that business is being "right-sized."
            </p>
            <br />
            <br />
            <p>
              "This market had a massive over-supply of everything which is now
              being corrected," he said. "Changes that were long due had and
              will get accelerated. Technology will enable changes, but we also
              have to rethink the fundamentals of doing business. Technology
              adaptation and deployment of robots could be a game-changer in
              retail business. We might see this happening soon."
            </p>
            <br />
            <br />
            <p>
              Consumer behaviour in the GCC saw a drastic shift towards online
              payment following the outbreak of the Covid-19 pandemic. A recent
              survey conducted by Ernst &amp; Young found that 92 per cent of
              the consumers in the UAE and Saudi Arabia have changed their
              shopping habits - including shifting to online purchases.
            </p>
            <br />
            <br />
            <p>
              Similarly, the Visa CEMEA Impact Tracker pointed to a shift to
              online commerce, with cash transactions being replaced by digital
              payments. The survey found that many consumers in the UAE started
              shopping online for the first time, during the pandemic. Around 68
              per cent of UAE consumers said that Covid-19 led to their first
              online grocery purchase, while 70 per cent have made their first
              online purchase from pharmacies.
            </p>
            <br />
            <br />
            <p>
              Piyush Kumar Chowhan, group chief information officer at Lulu
              Group International, said that data will be the new engine for
              growth in the retail sector. "Technology will drive digital
              initiatives. Good customer experience will come from an innovation
              mindset, driven using different technology tools. In the coming
              days, customers will make purchase order based on experience. So,
              retailers need to ask how they can make data the engine for the
              digital transformation. Data and artificial intelligence will
              nurture the growth of the retail sector."
            </p>
            <br />
            <br />
            <p>
              "Our sector has been highly resilient through the pandemic," said
              Waleed AR Bin Dawood, CCO at BinDawood Holding. "During the
              lockdown, there was huge demand as customers stocked up and
              prepared food at home. Our online platforms - both BinDawood and
              Danube Online - saw huge traction, so much so that we had to
              expand the capacity of operations. We have also leveraged our
              mobile apps, coupled with the store network to meet the surge in
              demand. We have used our wide store network as warehouses to
              support our online sales. We are constantly enhancing the seamless
              experience through product availability, pricing and delivery."
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
