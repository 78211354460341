import React from "react";
import { Container, Row, Col } from "reactstrap";

function TextGrid() {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
        }}
        className="section section-with-space p-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row>
            <Col lg={4} sm={12} className="py-5 text-center">
              <img
                src={require(`assets/images/award.jpg`)}
                width="100%"
                alt=""
              />
            </Col>
            <Col lg={8} sm={12} className="mb-auto mt-auto">
              <Container className="py-5">
                <Row>
                  <Col lg={12}>
                    <div className="motto">
                      <p>
                        <br />
                        Khaleej Times UAE’s 1st English newspaper with a legacy
                        of over 42 years is proud to announce the KSA-CISO
                        POWERLIST 2020 co-located with the{" "}
                        <b>
                          Virtual FutureSec Summit in July 2022
                        </b>
                        . KSA-CFO POWERLIST 2020 will shine a spotlight, honor
                        and celebrate exemplary achievements, digital
                        leadership, innovation and raised benchmarks by top 20
                        CISOs from the field of Cybersecurity in the Kingdom of
                        Saudi Arabia.
                        <br />
                        <br />
                        Chief Information Security Officers are the leaders and
                        champions for change. The Powerlist aims at recognizing
                        and acknowledging a CISO’s commitment to fulfilling
                        digital & secure strategies best for the company, while
                        remaining true to the core values that define him/her as
                        an individual.
                        <br />
                        <br />
                        <b>
                          The KSA-CISO Powerlist will highlight excellence,
                          shine a spotlight on the innovation and vision of the
                          most digitally-savvy companies across a wide range of
                          sectors that have led to business growth.
                        </b>
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;
