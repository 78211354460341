import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              When you pick up a magazine, scroll through the tech blogs, or
              simply chat with your peers about technology, you&rsquo;ll quickly
              notice that almost everything related to the technology world
              seems to have some element of artificial intelligence or machine
              learning to it. Computer power is developing, calculations and
              Artificial Intelligence (AI) models are getting increasingly
              advanced, and, maybe generally significant of all, the world is
              creating impossible volumes of data.
            </p>
            <br />
            <br />
            <p>
              As a result, AI is being blended into almost every aspect of our
              lives, from our cars and medical devices to robots and
              entertainment. It&rsquo;s here to prevail. Artificial intelligence
              will likely revamp every aspect of society, business, and industry
              over the coming decade. AI could impact everything from customers
              to employees to operations, making it indispensable that
              organizations begin understanding their place in this era of AI.
            </p>
            <br />
            <br />
            <p>
              Now that we are well immersed into the AI revolution, it&rsquo;s
              important to look at how the concept of artificial intelligence
              has been absorbed, why, and what it will mean in the future. The
              AI of today is a continuation of advances accomplished over the
              recent decades. The change, the reasons we are seeing artificial
              intelligence show up in such a large number of more places,
              isn&rsquo;t such a great amount about the AI advancements
              themselves, yet the innovations that encompass them&mdash; data
              generation and handling power.
            </p>
            <br />
            <br />
            <p>
              Ongoing advances in artificial intelligence have come essentially
              in zones where information researchers can copy human recognition
              capabilities, for example, perceiving objects in pictures or words
              in acoustic signs. Figuring out how to perceive designs in complex
              signs, for example, sound streams or pictures, is amazingly
              incredible&mdash;ground-breaking enough that numerous individuals
              wonder why we aren&rsquo;t utilizing deep learning procedures
              everywhere.
            </p>
            <br />
            <br />
            <p>
              Pushing ahead, as groups become adjusted in their objectives and
              techniques for utilizing AI to accomplish them, deep learning will
              turn out to be a piece of each data scientist&rsquo;s tool box.
              Consider this thought. We will have the option to incorporate
              object recognition in a framework, utilizing a pre-prepared
              artificial reasoning framework. However, at long last, we will
              understand that profound learning is simply another tool to
              utilize when it makes sense.
            </p>
            <br />
            <br />
            <p>
              Now let&rsquo;s explore how AI is benefitting the mankind and
              serving various fields like marketing, finance, banking and so on
              in the real world.
            </p>
            <br />
            <br />
            <p>
              Marketing is a way to glorify your products to attract more
              customers. In the early 2000s, in the event that we looked through
              an online store to discover an item without knowing its precise
              name, it would turn into a nightmare to discover the item. In any
              case, presently when we scan for a thing on any e-commerce store,
              we get every single imaginable result identified with the item.
            </p>
            <br />
            <br />
            <p>
              A classic case of this is finding the right movies on Netflix. It
              examines millions of records to recommend shows and films that you
              might like based on your previous choices of films. As the data
              deposit grows, this technology is getting smarter and smarter
              every day.
            </p>
            <br />
            <br />
            <p>
              AI has expanded its reach in the world of banking as well. AI
              solutions can be used to strengthen security across a number of
              business sectors, including retail and finance. By tracking card
              usage and endpoint access, security experts are more effectively
              preventing fraud. Organizations rely on AI to trace those
              anomalies by reviewing the behaviour of transactions.
            </p>
            <br />
            <br />
            <p>
              In recent times, ventures have been depending on computers and
              information researchers to decide future patterns in the market.
              Trading for the most part relies upon the capacity to foresee the
              future precisely. Machines are incredible at this since they can
              crunch a colossal measure of information in a limited time.
            </p>
            <br />
            <br />
            <p>
              In the time of ultra-high-recurring trade, monetary associations
              are going to AI to improve their stock trading performance and
              boost profit.
            </p>
            <br />
            <br />
            <p>
              However, there are certain barriers to the rapid growth of AI.
              These barriers demonstrate that the path to the advancement of AI
              can be tricky and somewhat challenging. The present artificial
              intelligence systems don&rsquo;t have that deep understanding.
              What we see presently is shallow intelligence; the ability to copy
              confined human recognition abilities and sometimes outperforms
              humans on those secluded tasks.
            </p>
            <br />
            <br />
            <p>
              Apart from this, teaching computers to learn for themselves is an
              exceptional shortcut. There&rsquo;s intelligence in AI systems,
              but it&rsquo;s not organic intelligence, and it doesn&rsquo;t
              follow the same rules as humans do.
            </p>
            <br />
            <br />
            <p>
              Intelligence is a rare and valuable commodity. Regardless of
              ongoing advances in Artificial Intelligence (AI) that empower it
              to win games and drive vehicles, there are innumerable
              undiscovered open doors for trend setting technology to have a
              noteworthy and gainful impact on the world. Driven by three major
              patterns, we&rsquo;re as of now in the center of an incredible new
              wave of AI.
            </p>
            <br />
            <br />
            <p>
              SOURCE:{" "}
              <a href="https://www.analyticsinsight.net/the-advancements-in-real-world-artificial-intelligence/">
                Analytics Insight
              </a>
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
