import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import { sponsors } from "./content";
// import Testimonials from "components/Testimonials.js";

import WhoAttend from "components/WhoAttend";
// import PriceCard from "components/PriceCard.js";

function AboutPage() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader
        title="WHY SHOULD YOU ATTEND?"
        bgColor="#fff"
      />
      <div className="section profile-content" style={{ padding: 0 }}>
        <WhoAttend />
      </div>
      {/* <PriceCard /> */}
      {/* <Testimonials /> */}
      {/* <Sponsor sponsors={sponsors} /> */}
      <Footer />
    </>
  );
}

export default AboutPage;
