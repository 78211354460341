import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              The historic move by the US to issue the long-awaited guidance on
              a clear regulatory framework for the safe deployment of the
              revolutionary hyperloop system will be critical in turning the
              new-age&nbsp;transportation concept into a reality in the UAE and
              other countries, DP World, a global leader in logistics, said on
              Wednesday.
            </p>
            <br />
            <br />
            <p>
              Praising the&nbsp;move by the US House of Representatives to pass
              legislation seeking a regulatory framework for hyperloop
              systems&nbsp;within six months, DP World said the game-changing
              technology "is one major step closer to reality."
            </p>
            <br />
            <br />
            <p>
              DP World,&nbsp;which is among those in the forefront to make the
              revolutionary idea a reality,&nbsp;and Virgin Hyperloop have
              joined to set up&nbsp;the "DP World Cargospeed" venture&nbsp;to
              provide hyperloop-enabled cargo systems that will facilitate the
              fast, sustainable and efficient delivery of palletised cargo
              around the world.
            </p>
            <br />
            <br />
            <p>
              First proposed by Elon Musk, the&nbsp;Hyperloop&nbsp;is a
              high-speed transportation system in which specialized pods are
              accelerated through a low-pressure tube to achieve speeds near the
              speed of sound. The system is intended to provide a more
              cost-effective and faster mode of travel between cities separated
              by distances less than approximately nine hundred miles.
            </p>
            <br />
            <br />
            <p>
              Hyperloop systems would not only support passengers but also
              high-priority, on-demand goods, allowing deliveries to be
              completed in hours versus days with greater reliability and fewer
              delays.&nbsp;This can shrink inventory lead times, help reduce
              finished goods inventory and cut required warehouse space and cost
              by 25 per cent.
            </p>
            <br />
            <br />
            <p>
              "We applaud hyperloop's bipartisan supporters for their
              forward-leaning stance on advanced technologies, including several
              innovative provisions that will support the transportation sector
              in the 21st century and beyond," said&nbsp;Sir Richard Branson,
              Founder of the Virgin Group.
            </p>
            <br />
            <br />
            <p>
              The Non-Traditional and Emerging Transportation Technology (NETT)
              Council in the US is expected to issue guidance within the next
              six months to help&nbsp;set&nbsp;out a clear regulatory framework
              for the industry.
            </p>
            <br />
            <br />
            <p>
              Sultan Bin Sulayem, group chairman and CEO of DP World and
              chairman of VH, said the revolutionary vision promises to become
              part of the future of transportation. "We are confident that
              Virgin Hyperloop will take a leap forward and deliver on a
              21st-century transportation solution. The decision is a huge vote
              of confidence that we are all on the right side of history. The
              move, which brings hyperloop systems one major step closer to
              reality, validates our decision to take this technology seriously
              and support this innovation."
            </p>
            <br />
            <br />
            <p>
              Virgin Hyperloop&nbsp;has been engaging with governments in the
              region including UAE, Saudi Arabia, and India. It recently
              conducted the world's first national-level study in partnership
              with the Saudi Ministry of Transport.
            </p>
            <br />
            <br />
            <p>
              "We seek to revolutionise regional transport and trade sectors
              through a passenger and cargo hyperloop-enabled system, powered
              by&nbsp;artificial intelligence (A,"&nbsp;said Sulayem.
            </p>
            <br />
            <br />
            <p>
              This month,&nbsp;Virgin Hyperloop signed a Memorandum of
              Understanding with the UAE's Mohamed bin Zayed University of
              Artificial Intelligence, the&nbsp;world's&nbsp;first
              graduate-level, research-based AI university, to initiate a
              collaborative approach to research and innovation that we believe
              will culminate in making the UAE a centre for the development of
              this technology.
            </p>
            <br />
            <br />
            <p>
              "Our aim is to be a catalyst&nbsp;for global adoption and
              expansion of this new mode of transportation that will be faster,
              safer, cheaper, and more sustainable than other existing modes,"
              said Sulayem.
            </p>
            <br />
            <br />
            <p>
              Virgin Hyperloop is the California-based category leader in
              hyperloop development and the only hyperloop company in the world
              to successfully test its hyperloop technology at scale. It has
              raised more than $400 million in investments, including funds from
              DP World.
            </p>
            <br />
            <br />
            <p>
              "DP World has been a key enabler of developing this technology for
              deployment, and the inclusion of hyperloop transportation in this
              legislation is a great moment in history for us and for DP World,"
              said Jay Walder, Virgin Hyperloop CEO.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
