import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              Big-data health-care solutions are being rushed to market in
              without meaningful regulation, transparency, standardisation,
              accountability, or robust validation practices
            </p>
            <br />
            <br />
            <p>
              In an interview with the&nbsp;Wall Street Journal earlier this
              year, David Feinberg, the head of Google Health and a
              self-professed astrology buff, enthused that, "If you believe me
              that all we are doing is organising information to make it easier
              for your doctor, I'm going to get a little paternalistic here: I'm
              never going to let that get opted out." In other words, patients
              will soon have no choice but to receive personalised clinical
              horoscopes based on their own medical histories and inferences
              drawn from a growing pool of patient records. But even if we want
              such a world, we should take a hard look at what today's
              health-tech proponents are really selling.
            </p>
            <br />
            <br />
            <p>
              In recent years, most of America's Big Tech firms - along with
              many startups, the Big Pharma companies, and others - have entered
              the health-tech sector. With big-data analytics, artificial
              intelligence, and other novel methods, they promise to cut costs
              for struggling health-care systems, revolutionise how doctors make
              medical decisions, and save us from ourselves. What could possibly
              go wrong?
            </p>
            <br />
            <br />
            <p>
              Quite a lot, it turns out. In Weapons of Math Destruction, data
              scientist Cathy O'Neil lists many examples of how algorithms and
              data can fail us in unsuspecting ways. When transparent
              data-feedback algorithms were applied to baseball, they worked
              better than expected; but when similar models are used in finance,
              insurance, law enforcement, and education, they can be highly
              discriminatory and destructive.
            </p>
            <br />
            <br />
            <p>
              Health care is no exception. Individuals' medical data are
              susceptible to subjective clinical decision-making, medical
              errors, and evolving practices, and the quality of larger data
              sets is often diminished by missing records, measurement errors,
              and a lack of structure and standardisation. Nonetheless, the
              big-data revolution in health care is being sold as if these
              troubling limitations did not exist. Worse, many medical
              decision-makers are falling for the hype.
            </p>
            <br />
            <br />
            <p>
              One could argue that as long as new solutions offer some benefits,
              they are worth it. But we cannot really know whether data
              analytics and AI actually do improve on the status quo without
              large, well-designed empirical studies. Not only is such evidence
              lacking; there is no infrastructure or regulatory framework in
              place to generate it. Big-data applications are simply being
              introduced into health-care settings as if they were harmless or
              unquestionably beneficial.
            </p>
            <br />
            <br />
            <p>
              Consider Project Nightingale, a private data-sharing arrangement
              between Google Health and Ascension, a massive non-profit health
              system in the US. When the&nbsp;Wall Street Journal first reported
              on this secret relationship last November, it triggered a scandal
              over concerns about patient data and privacy. Worse, as Feinberg
              admitted just two months later, "We didn't know what we were
              doing."
            </p>
            <br />
            <br />
            <p>
              Given that the Big Tech companies have no experience in
              healthcare, such admissions should come as no surprise, despite
              the attempts to reassure us otherwise. Worse, at a time when
              individual privacy is becoming more of a luxury than a right, the
              algorithms that are increasingly ruling our lives are becoming
              inaccessible black boxes, shielded from public or regulatory
              scrutiny to protect corporate interests. And in the case of
              healthcare, algorithmic diagnostic and decision models sometimes
              return results that doctors themselves do not understand.
            </p>
            <br />
            <br />
            <p>
              Although many of those pouring into the health-tech arena are
              well-intentioned, the industry's current approach is fundamentally
              unethical and poorly informed. No one objects to improving health
              care with technology. But before rushing into partnerships with
              tech companies, health-care executives and providers need to
              improve their understanding of the health-tech field.
            </p>
            <br />
            <br />
            <p>
              For starters, it is critical to remember that big-data inferences
              are gleaned through statistics and mathematics, which demand their
              own form of literacy. Another critical area is AI, which requires
              both its own architecture - that is, the rules and basic logic
              that determine how the system operates - and access to massive
              amounts of potentially sensitive data. The goal is to position the
              system so that it can 'teach' itself how to deliver optimal
              solutions to stated problems. But, here, one must remember that
              the creators of the architecture - the people writing the rules
              and articulating the problems - are as biased as anyone else,
              whether they mean to be or not. Moreover, AI systems are guided by
              data from the current health-care system, making them prone to
              replicating its own failures and successes.
            </p>
            <br />
            <br />
            <p>
              At the end of the day, improving health care through big data and
              AI will likely take much more trial and error than
              techno-optimists realise. If conducted transparently and publicly,
              big-data projects can teach us how to create high-quality data
              sets&nbsp;prospectively, thereby increasing algorithmic solutions'
              chances of success.&nbsp;
            </p>
            <br />
            <br />
            <p>
              Above all, health-care providers and governments should remove
              their rose-tinted glasses and think critically about the
              implications of largely untested new applications in health care.
              Having been massively overhyped, big-data health-care solutions
              are being rushed to market in without meaningful regulation,
              transparency, standardisation, accountability, or robust
              validation practices. Patients deserve health systems and
              providers that will protect them, rather than using them as mere
              sources of data for profit-driven experiments.&nbsp;
            </p>
            <br />
            <br />
            <p>
              <em>
                Leeza Osipenko is Senior Lecturer in Practice in the Department
                of Health Policy at the London School of Economics and Political
                Science. -Project Syndicate&nbsp;
              </em>
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
