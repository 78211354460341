import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";


function KeyNotes() {
  return (
    <>
      <div
        className="section"
        style={{
          overflow: "hidden",
          background: "rgb(26, 34, 89)",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col className="mx-auto pb-5" md={12}>
              <h1 className="title mt-0 mb-3 section-title text-center text-white">
                <b>Key Findings and Statistics</b>
              </h1>
              <br />
            </Col>

            <div className="p-1">
              {/* Image1 & Text1 */}
              <div className="d-flex flex-column flex-sm-row align-items-center px-3">
                <img src="/images/kfs-1.svg" className="kfs-img-1 mb-5 mb-sm-0" alt="image1" />

                <span className="kfs-text-1 text-white text-center">The UAE cybersecurity market is expected to reach $1.07 billion by 2029, growing at a CAGR of 12.72% from 2024.</span>
              </div>

              <hr className="hr kfs-hr my-5" />

              {/* Image2 & Text2 & Image3 & Text3 */}
              <div className="p-1 d-lg-flex">
                {/* Image2 & Text2 */}
                <div className="ksf-div-2 d-flex flex-column flex-sm-row-reverse flex-lg-row align-items-center px-3">
                  <img src="/images/kfs-2.svg" className="kfs-img-2 mb-5 mb-sm-0" alt="image2" />
                  <span className="kfs-text-2 text-white text-center">Over 85% of UAE organizations experienced at least one cybersecurity incident in the past year</span>
                </div>

                <hr className="kfs-hr hr my-5 d-lg-none" />

                {/* Image3 & Text3 */}
                <div className="d-flex flex-column flex-sm-row flex-lg-column align-items-center px-3">
                  <img src="/images/kfs-3.svg" className="kfs-img-3 mb-5 mb-sm-0 mb-lg-4" alt="image3" />
                  <span className="kfs-text-3 text-white text-center">The average data breach cost in UAE was $6.53 million in 2023, higher than the global average of $4.45 million.</span>
                </div>
              </div>

              <hr className="hr kfs-hr my-5" />

              {/* Image4 & Text4 */}
              <div className="d-flex flex-column flex-sm-row-reverse align-items-center px-3">
                <img src="/images/kfs-4.png" className="kfs-img-4" alt="image4" />

                <span className="kfs-text-4 text-white text-center">The rise of remote work has significantly increased cybersecurity risks, with more phishing attacks targeting remote workers.</span>
              </div>

              <hr className="hr kfs-hr my-5" />

              {/* Image5 & Text5 & Image6 & Text6 */}
              <div className="p-1 d-lg-flex">
                {/* Image5 & Text5 */}
                <div className="ksf-div-5 d-flex flex-column flex-sm-row flex-lg-column align-items-center px-3">
                  <img src="/images/kfs-5.svg" className="kfs-img-5 mb-5 mb-sm-0 mb-lg-4" alt="image3" />
                  <span className="kfs-text-5 text-white text-center">UAE has implemented cybersecurity regulations to strengthen the country's security posture, compliance with which is crucial for organizations.</span>
                </div>

                <hr className="kfs-hr hr my-5" />

                {/* Image6 & Text6 */}
                <div className="d-flex flex-column flex-sm-row-reverse flex-lg-column align-items-center px-3">
                  <img src="/images/kfs-6.svg" className="kfs-img-6 mb-5 mb-sm-0 mb-lg-4" alt="image3" />
                  <span className="kfs-text-6 text-white text-center">Cybersecurity investments are considered necessary in the UAE due to the high costs of successful Cyber-Attacks.</span>
                </div>
              </div>
            </div>




            {/* old code */}
            {/* {
              content.map((data, index) => (
                <Col key={index} className="pb-3 col-12 col-sm-6 col-md-4 mb-5">
                  <div style={{ border: "5px solid rgb(1, 108, 173)", padding: "5px" }}>
                    <div className="text-center">
                      <img
                        src={data.image}
                        alt=""
                        width="100%"
                        style={{ height: "100px", width: '100px', marginTop: "-50px" }}
                      />
                      <div
                        style={{ background: "rgb(1, 108, 173)", minHeight: "115px" }}
                        className="p-3 mt-2"
                      >
                        <h3 className="text-700 text-uppercase pt-3 text-white">
                          {data.title}
                        </h3>
                      </div>
                    </div>
                  </div>
                </Col>
              ))
            } */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default KeyNotes;
