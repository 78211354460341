import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              'In many ways, protecting our electronic borders is similar to
              protecting our maritime and land borders,' Dubai Crown Prince
              says.
            </p>
            <br />
            <br />
            <p>
              Sheikh Hamdan bin Mohammed bin Rashid Al Maktoum, Crown Prince of
              Dubai and Chairman of Dubai Executive Council, said that Dubai has
              become a global leader in digital transformation over the last few
              years, guided by the directives of His Highness Sheikh Mohammed
              bin Rashid Al Maktoum, Vice-President&nbsp;and Prime
              Minister&nbsp;of the UAE&nbsp;and Ruler of Dubai.
            </p>
            <br />
            <br />
            <p>
              Sheikh Mohammed's vision to make Dubai the smartest and happiest
              city in the world has steered its transformation from e-government
              to Smart Government and eventually to a Smart City, Sheikh Hamdan
              said.
            </p>
            <br />
            <br />
            <p>
              The Dubai Crown Prince said Dubai places high priority on
              cyber&nbsp;security in its drive to shape the future.
            </p>
            <br />
            <br />
            <p>
              Raising the quality of its electronic infrastructure to increase
              its preparedness to deal with cyber risks is a strong focus for
              the emirate.
            </p>
            <br />
            <br />
            <p>
              His Highness's remarks came as he chaired a meeting of the
              Strategic Affairs Council of the Executive Council in the presence
              of His Highness Sheikh Maktoum bin Mohammed bin Rashid Al Maktoum,
              Deputy Ruler of Dubai and First Deputy Chairman of the Executive
              Council of Dubai and Sheikh Ahmed bin Saeed Al Maktoum Second
              Deputy Chairman of the Executive Council of Dubai.
            </p>
            <br />
            <br />
            <p>
              About 60 officials representing various entities of the Dubai
              Government also attended the meeting.
            </p>
            <br />
            <br />
            <p>
              "Digital infrastructure and smart services are key pillars of our
              future. Strong cyber security is crucial to ensure we can deal
              with all threats and risks proactively and efficiently. In many
              ways, protecting our electronic borders is similar to protecting
              our maritime and land borders.
            </p>
            <br />
            <br />
            <p>
              "Rapid technological advancement brings with it growing risks. As
              Dubai speeds up the pace of its digital transformation,
              reinforcing cyber security is vital to our sustained progress,"
              Sheikh Hamdan said.
            </p>
            <br />
            <br />
            <p>
              "Dealing with challenges associated with cyber security is a
              common responsibility. To provide individuals and society a high
              degree of protection from cyber threats, we need to consolidate
              and synergise the efforts of various stakeholders. The Dubai Cyber
              Security Strategy and Dubai Cyber Index seek to create
              comprehensive protection from security risks, which is a critical
              element in bolstering Dubai's leadership in the digital arena. We
              will continue our journey of innovation and development to make
              Dubai the world's safest city in the digital world," His Highness
              further said.
            </p>
            <br />
            <br />
            <p>
              The recently launched Dubai Cyber Index seeks to raise the
              security and safety standards of Dubai's electronic infrastructure
              by enhancing the readiness of government entities in dealing with
              cyber crises and emergency scenarios. The initiative will use
              advanced Artificial Intelligence technologies and Big Data
              analysis to help organisations anticipate potential cyber threats.
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
