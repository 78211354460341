import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>
              Five Steps To Making the Most of Khaleej Times Artelligence Forum
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              We are so excited to be hosting two days of online content to
              discuss the best practices in AI implementation with sessions from
              experts.
              <br />
              With hundreds of people attending the Khaleej Times Artelligence
              Forum we wanted to share our top six tips for getting the most out
              of the immersive online experience. You won’t get access to such a
              wide range of experts and businesses all in one place online
              anywhere else again!
            </p>
            <br />
            <br />
            <h3>Bookmark your favourite sessions.</h3>
            <p>
              We have two full days of live sessions, with virtual networking,
              panel discussions and talks running all day as well as over ten
              conference partners. Have a look at the event agenda in advance
              and plan your work day around the sessions you want to attend.
            </p>
            <br />
            <p>
              We recommend blocking the whole day out to gain as much access and
              knowledge as you can. If you have scheduled meetings, why not
              invite them along to enjoy the event as well as conduct meetings
              with our live platform?
            </p>
            <br />
            <p>
              Make sure you are registered with the event in advance and fill in
              your profile information. Don’t worry, your personal contact
              details are safe with us and won’t be visible to fellow attendees!
              Here’s a sample:
            </p>
            <br />
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/12.1.png`)}
              style={{ maxwidth: "300px" }}
            />
            <br />
            <p>
              For the best experience, we recommend Google Chrome or Firefox.
              Please avoid Brave, Safari, and Microsoft IE or Edge.
            </p>
            <br />
            <br />
            <h3>Get involved in the conversation.</h3>
            <p>
              There are multiple chat channels at Artelligence Forum, with a
              different purpose for each. The main event feed will keep you
              updated with the latest schedule of live sessions, contests, polls
              and quizzes. Each session has its own chat where you can meet
              others watching the same talk as you.
            </p>
            <br />
            <p>
              What you may have not seen in other events is that we also have
              Virtual Booths where you can talk to representatives from some of
              the biggest brands and get access to incredible deals just like
              you would at a normal conference or trade show.
            </p>
            <br />
            <p>
              You can also direct message other attendees/partners if you want
              to speak in private or discuss a panel you attended together
              further. So many different ways to connect and network!
            </p>
            <br />
            <br />
            <h3>Explore the Virtual Expo.</h3>
            <p>
              We’ve gathered over ten incredible brands, all providing deals and
              discounts that are exclusive to our attendees.
            </p>
            <br />
            <p>
              You can visit the booths whenever you want and during the
              networking breaks. There will be brand representatives throughout
              the day within the booth chat to answer any questions you have
              about their products and services.
            </p>
            <br />
            <p>
              This is great way to see what is available to set your AI-powered
              future in motion as well as an opportunity to grab yourself an
              exclusive deal!
            </p>
            <br />
            <br />
            <h3>Conduct Virtual Meetings.</h3>
            <p>
              Meet one-on-one with your peers through virtual meetings at
              Artelligence Forum. These one-on-one meetings are similar to a
              Zoom/Webex meeting and gives you the option to leave at any time.
            </p>
            <br />
            <p>
              As an added bonus, whoever gets the most 1-1 meetings will win an
              exclusive e-gift voucher worth AED 500!!
            </p>
            <br />
            <br />
            <h3>Take part in Networking Lounge.</h3>
            <br />
            <br />
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/12.2.png`)}
              style={{ maxwidth: "300px" }}
            />
            <br />
            <p>
              The Networking Lounge is the place where people meet and
              participate in roundtable discussions on the most trending topics
              at the Artelligence Forum. Grab your cup of coffee and connect
              with other people in a way you can’t in a busy webinar.
            </p>
            <br />

            <br />
            <br />
            <p>
              If you are joining us this Tuesday and Wednesday, don’t forget to
              tag us on social media #artelligenceforum so we can see where you
              are watching from! In case you haven’t yet registered, here’s the
              registration link: https://artelligenceforum.com/register
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
