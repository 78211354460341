import React from "react";

import { Container, Row, Col } from "reactstrap";
// import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "p-10",
  };

  return (
    <>
      <div
        style={{
          background:
            "linear-gradient(90deg, rgba(25,70,121,1) 9%, rgba(14,38,63,1) 75%)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
        className="section section-with-space"
        data-parallax={true}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="">
                <h1 className="section-title text-center text-white">
                  <b>HEAR WHY INDUSTRY LEADERS PARTICIPATE</b>
                </h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="text-center">
              <img
                src={require("assets/images/icons/quote.png")}
                style={{ maxWidth: "100px" }}
                alt="quote"
              />
              <Slider {...settings}>
                {content.map((c) => (
                  <>
                    <br />
                    <br />
                    <h2
                      className="text-white text-600"
                      dangerouslySetInnerHTML={{ __html: c.testimonial }}
                    />
                    <br />
                    <br />
                    <h3 className="text-white text-800">{c.by}</h3>
                  </>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

const content = [
  {
    testimonial: `
    The strength of attendees were beyond
    our expectations. It was a great relief
    seeing actual houseful of attendees. We
    had a few leads from the event and let’s
    hope that we can convert them!
	`,
    by: "Sayer R. Ahmed, Marketing Director, Security Matterz",
  },
  {
    testimonial: `
    This is something that Trend Micro
    would want to have its name associated
    with for the future and we see great
    value partnering with FutureSec Summit
    hopefully in the near future
	`,
    by:
      " Dr. Moataz Bin Ali, Vice President, Middle East & North Africa, Trend Micro",
  },
];

export default Testimonials;
