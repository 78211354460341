import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";

// import Testimonials from "components/Testimonials.js";
import KeyTopics from "components/KeyTopics.js";

import Stats2 from "components/Stats2.js";
import Stats from "components/Stats.js";
import SpeakerCard from "components/SpeakerCard.js";
import Sponsor from "components/Sponsor.js";
// import { speakers, sponsors } from "./content";
// import Slider from 'react-slick';


import "../assets/demo/xs.css";
import "../assets/demo/sm.css";
import "../assets/demo/md.css";
import "../assets/demo/lg.css";
import "../assets/demo/xl.css";
import "../assets/demo/xxl.css";
import { Container, Row, Col, Card, Button } from "reactstrap";


function Home() {
  const [speakers, setspeakers] = useState([])
  const [speakersVIP, setspeakersVIP] = useState([])
  const [sponsors, setsponsors] = useState([])
  const [supportings, setSupportings] = useState([])
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/futuresec/data.json?v=3rdOct${new Date()}`, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        setspeakersVIP(data.speakersVIP);
        setspeakers(data.speakers);
        setsponsors(data.sponsors);
        setSupportings(data.supporting);
      })
      .catch((err) => console.log(err));
  }, []);

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  // Images for Primary Industries
  const industries = [
    `/images/govPubSec.png`,
    `/images/bfsi.png`,
    `/images/itTele.png`,
    `/images/healthcare.png`,
    `/images/manufacturing.png`,
    `/images/retail.png`,
    `/images/energyUtilities.png`,
    `/images/education.png`,
  ];

  // Sponsers
  const sponserd = [
    {
      image: '/images/bs-1.svg',
      text: 'CYBERSECURITY SOLUTION PROVIDERS'
    },
    {
      image: '/images/bs-2.svg',
      text: 'IT SERVICE MANAGEMENT COMPANIES'
    },
    {
      image: '/images/bs-3.svg',
      text: 'CLOUD SERVICE PROVIDERS'
    },
    {
      image: '/images/bs-4.svg',
      text: 'CONSULTING FIRMS'
    },
    {
      image: '/images/bs-5.svg',
      text: 'LAW FIRMS SPECIALIZING IN CYBER LAW'
    },
    {
      image: '/images/bs-6.svg',
      text: 'HARDWARE AND SOFTWARE VENDORS'
    },
    {
      image: '/images/bs-7.svg',
      text: 'CYBERSECURITY TRAINING AND EDUCATION PROVIDERS'
    },
  ];

  // Top industry challenges
  const challenges = [
    {
      image: '/images/gs-1.svg',
      text: 'Rising Cybersecurity Skill Gap'
    },
    {
      image: '/images/gs-2.svg',
      text: 'Sophistication of Cyber Attacks'
    },
    {
      image: '/images/gs-3.svg',
      text: 'Ensuring Cloud Security'
    },
    {
      image: '/images/gs-4.svg',
      text: 'Managing Third-party Risks'
    },
    {
      image: '/images/gs-5.svg',
      text: 'Staying Compliant with Evolving Regulations'
    },
    {
      image: '/images/gs-6.svg',
      text: 'Balancing Security with User Experience'
    },
    {
      image: '/images/gs-7.svg',
      text: 'Protecting Against Insider Threats'
    },
  ];

  // Top Industry Trends
  const trends = [
    {
      image: '/images/tit-1.svg',
      text: `Increasing Adoption of AI 
      and Machine Learning`,
    },
    {
      image: '/images/tit-2.svg',
      text: `The Rise of 
      Quantum Computing`,
    },
    {
      image: '/images/tit-3.png',
      text: `Enhanced Cloud 
      Security Measures`,
    },
    {
      image: '/images/tit-4.svg',
      text: `Greater Emphasis 
      on Zero Trust Security`,
    },
    {
      image: '/images/tit-5.svg',
      text: `Advanced Threat 
      Detection and Response`,
    },
    {
      image: '/images/tit-7.svg',
      text: `Growing Focus on 
      Data Privacy and 
      Compliance`,
    },
  ];

  // Event Agenda
  const eventAgendas = [
    {
      time: '08:00 - 09:00',
      session: '<h3>Registration, Networking & Welcome Coffee</h3>',
    },
    {
      time: '09:00 - 09:05',
      session: '<h3>Welcome Address, Michael Jabri Pickett, Editor in Chief, Khaleej Times</h3>',
    },
    {
      time: '09:05 - 09:20',
      session: `<h4>Opening Keynote Address</h4>
        <ul>
          <li>H.E. Dr Mohamed Al Kuwaiti, Head of Cybersecurity, UAE Government</li>
        </ul>`,
    },
    {
      time: `09:20 - 09:35`,
      session: `<h4>Special Keynote Address</h4>
        <ul>
          <li>H.E. Eng. Salem Al Qubaisi, Director General, UAE Space Agency</li>
        </ul>`,
    },
    {
      time: `09:35 - 10:15`,
      session: `<h4>Panel Discussion | The Governance Code: Navigating Compliance in a Digital Age</h4>
        <ul>
          <li>Risk Management & Data Protection.</li>
          <li>Legal & Regulatory Adherence.</li>
          <li>Operational Efficiency & Trust.</li>
        </ul>
        <ol>
          <li>Moderator: Dr Sana Amairi Pyka - Lead Scientist - Technology Innovation Institute </li>
          <li>Lt. Col. Saeed AlShebli, Deputy Director of Digital Security Department, Ministry of Interior UAE</li>
          <li>Dr. Ebrahiem Al Alkeem, National Risk & Policies Director, AML & CTF, UAE Government</li>
          <li>Ali Katkhada, Group CIO, Depa Group</li>
          <li>Amit Mehta , CISO & Cybersecurity Advisory Board Member, Mastercard</li>
          <li>Sona Saha Das, Lead Data Strategy Governance & Analytics</li>
        </ol>`,
    },
    {
      time: `10:15 - 10:30`,
      session: `<h4>Keynote Address | Vision for a Secure Digital Future:</h4>
        <ul>
          <li>Amb. Dunston Pereira, CEO, Private Office of H.H. Sheikh Ahmed Bin Faisal Al-Qassimi</li>
        </ul>`,
    },
    {
      time: `10:30 - 11:10`,
      session: `<h4>Panel Discussion | The Rapid Response Blueprint: Managing Cyber Crisis with Confidence</h4>
        <ul>
          <li>Timely Detection & Mitigation.</li>
          <li>Structured Response Plans.</li>
          <li>Resilience & Continuity.</li>
        </ul>
        <ol>
          <li>Moderator: Hala ElGhawi, Regional Information and Cybersecurity Risk Lead, Standard Chartered Bank</li>
          <li>Victor Sergeev, Incident Response Lead, Kaspersky</li>
          <li>Jayakumar, Group Chief Information Officer, Buzeki Enterprises</li>
          <li>Padam Kafle, Head of Innovation, Aster Digital Health</li>
          <li><b>Dr Victor Mateu, Lead Research, Technology Innovation Institute</b></li>
        </ol>`,
    },
    {
      time: `11:10 - 11:25`,
      session: `<h4>Presentation | Trust, But Verify: Mastering Zero Trust Architecture</h4>
        <ul>
          <li>Strong Identity Verification.</li>
          <li>Continuous Monitoring and Validation.</li>
          <li>Enhanced Device Visibility and Network Segmentation.</li>
        </ul>
        <h4>Kanesan Pandi, Head of Information Security, Galadari Brother</h4>`,
    },
    {
      time: `11:25 - 11:40`,
      session: `<h3>Prayer & Networking Break</h3> `,
    },
    {
      time: `11:40 - 12:00`,
      session: `<h3>Dubai Drums Show</h3> `,
    },
    {
      time: `12:00 - 12:15`,
      session: `<h4>Presentation | DevSecOps Unleashed: Embedding Security in Every Step of Development</h4>
        <ul>
          <li>Integrating security practices within the DevOps process to achieve continuous security.</li>
          <li>Embedding Security in CI/CD Pipelines.</li>
          <li>Continuous Security Testing & Proactive Security Measures.</li>
        </ul>
        <h4>Denis Yakimov, Head of Information Security and Privacy, Equiti Group</h4>`,
    },
    {
      time: `12:15 - 12:55`,
      session: `<h4>Panel Discussion | Privacy First: Navigating Data Protection in a Complex Regulatory Landscape</h4>
        <ul>
          <li>Establishment of Legal Frameworks.</li>
          <li>Data Security & Privacy Strategies.</li>
          <li>Minimizing Data Collection & Usage.</li>
        </ul>
        <ol>
          <li>Moderator: Khaleej Times - Abdulla Alriyami - Head of Content</li>
          <li>Rohit Bajpai, IT & IS Advisory Committee & Head of Internal Audit, Gulf Islamic Investments</li>
          <li>Ezzeddin Jradi, Chief Transformation Officer, Emicool</li>
          <li>Noorul Huq, Group Head of Information Technology, Sergas</li>
          <li>Bader Zayoud, ICM, Abu Dhabi Media</li>
        </ol>`,
    },
    {
      time: `12:55 - 13:10`,
      session: `<h4>Presentation | The Resilience Blueprint: Cybersecurity in the Age of Digital Transformation</h4>
        <ul>
          <li>Enhanced Cybersecurity Measures.</li>
          <li>Integration of AI in Cyber Defence.</li>
          <li>Collaboration & Knowledge Sharing.</li>
        </ul>
        <h4>Dr Ahmad AlTarawneh, Subject Matter Expert</h4>`,
    },
    {
      time: `13:10 - 13:50`,
      session: `<h4>Panel Discussion | Breaking Barriers: Empowering Women in Cybersecurity Leadership</h4>
        <ul>
          <li>Promoting Gender Diversity.</li>
          <li>Educational Initiatives.</li>
          <li>Awareness & Advocacy.</li>
        </ul>
        <ol>
          <li>Moderator:  Sheeba Hasnain, Chief Information Officer, Sentients & Member of Women in AI</li>
          <li>Hessa Al Matrooshi, Cybersecurity Lead, Free Zone Authority & Board Member at WiCSME</li>
          <li>Oma Martins - Associate Director & Head of IT-GRC at WiCSME</li>
          <li>Badreya AlMehairi - AVP Senior Data Privacy, Mashreq Bank</li>
        </ol>`,
    },
    {
      time: `13:50 - 14:05`,
      session: `<h4>Presentation | Cloud Guardians: Best Practices for Securing Digital Skies:</h4>
        <ul>
          <li>Implementing Strong Access Controls.</li>
          <li>Data Encryption & Regular Security.</li>
          <li>Continuous Monitoring & Incident Response.</li>
        </ul>
        <h4>Sona Saha Das, Lead Data Strategy Governance & Analytics</h4>`,
    },
    {
      time: `14:05 - 14:10`,
      session: `<h3>Closing Remarks</h3>`,
    },
    {
      time: `14:10 - 15:10`,
      session: `<h3>Networking Luncheon & End of Conference</h3>`,
    },
  ];

  // Supporting Associations
  // const supportings = [
  //   `/images/supporting/supporting-1.jpg`,
  //   `/images/supporting/supporting-2.png`,
  // ];

  /* const supportingSettings = {
    classNames: '',
    dots: false,
    infinite: true,
    speed: 500,
    spaceBetween: 30,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        }
      }
    ]
  }; */



  return (
    <>
      <div id="outer-container">
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            // backgroundImage: "url(" + require("assets/images/BG.png") + ")",
            // backgroundImage: "url(" + require("assets/images/bg-1.png") + ")",
            backgroundImage: "url(" + require("assets/images/bg-2.png") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>

        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <Stats />
          <TextGrid />
          <Stats2 />
          <KeyTopics />


          {/* ----- Primary Industries ----- */}
          <section className="section"
            style={{
              overflow: "hidden",
              background: "rgb(26, 34, 89)",
            }}
          >
            <h1 className="text-white text-center section-title mb-5">Primary Industries</h1>

            <div className="pi-div">
              {
                industries.map((industry, index) => (
                  <img src={industry} className="pi-div-img col px-0" key={index} alt="govtpubsec" />
                ))
              }
            </div>
          </section>

          {/* ----- Sponsor ----- */}
          <section className="d-none section py-5" style={{ background: 'transparent' }}>
            <h1 className="text-center section-title pb-5 px-3" style={{ color: 'rgb(87, 200, 232)' }}>Who should sponsor?</h1>

            <div className="container row mx-auto">
              {
                sponserd.map((spon, index) => (
                  <div className="d-flex align-items-center col-12 col-md-6 col-lg-4 px-0 mb-3 mb-md-5" key={index}>
                    <img src={spon.image} className="me-3" style={{ maxWidth: '65px' }} alt="sdfsd" />

                    <span className="text-white text-600"
                      style={{ borderLeft: '3px solid rgb(164, 80, 139)', marginLeft: '15px', paddingInline: '15px' }}
                    >{spon.text}</span>
                  </div>
                ))
              }
            </div>
          </section>

          {/* ----- Top Industry Trends in 2024 ----- */}
          <section className="section" style={{ backgroundColor: 'rgb(26 34 89)' }}>
            <h1 className="text-center section-title px-3 pb-5 mb-5 text-white">Top Industry Trends in 2024</h1>

            <div className="row justify-content-center w-100 mx-auto">
              {
                trends.map((trend, index) => (
                  // Image & Text
                  <div className='text-center col-12 col-sm-6 col-lg-4' key={index}>
                    <img src={trend.image} className="tit-img mb-5" alt="image1" />

                    <p className="text-white tit-text-1 text-center">{trend.text}</p>

                    {(index !== 6) && <hr className="hr tit-hr my-5" />}
                  </div>
                ))
              }
            </div>
          </section>

          {/* ----- Top Industry Challenges ----- */}
          <section className="d-none section py-5" style={{ background: 'transparent' }}>
            <h1 className="text-center section-title pt-5 pb-5" style={{ color: 'rgb(87, 200, 232)' }}>Top Industry Challenges</h1>

            <div className="container row mx-auto">
              {
                challenges.map((challenge, index) => (
                  <div className="d-flex align-items-center col-12 col-md-6 col-lg-4 px-0 mb-3 mb-md-5" key={index}>
                    <img src={challenge.image} className="me-3" alt="sdfsd" />

                    <h3 className="text-white text-600"
                      style={{ borderLeft: '3px solid rgb(164, 80, 139)', marginLeft: '15px', paddingInline: '15px' }}
                    >{challenge.text}</h3>
                  </div>
                ))
              }
            </div>
          </section>

          {/* ----- Speakers ----- */}
          <div className="main">
            <section id="speakers" />
            <SpeakerCard speakers={speakers} speakersVIP={speakersVIP} />
            <section id="sponsors" />
            <Sponsor sponsors={sponsors} />
          </div>

          {/* ----- Supporting Associations ----- */}
          <section className='border section'>
            <h1 className='text-center' style={{ fontSize: '35px' }}>Supporting Associations</h1>

            <Container>
              <Row className="justify-content-center">
                {supportings.map((supporting) => (
                  <Col lg={supporting.no}>
                    <a href={supporting.url} className='px-2' key={supporting.id} target="_blank" rel="noopener noreferrer">
                      <Card style={{ borderRadius: "0" }}>
                        <img
                          src={supporting.image}
                          className='bg-white'
                          style={{ height: '200px', width: '100%', objectFit: 'contain' }}
                          alt={supporting.title}
                          title={supporting.title}
                        />
                      </Card>
                    </a>
                  </Col>
                ))}
              </Row>
            </Container>

            {/* <div className="container pt-5" style={{ width: '90%' }}>
              <Slider {...supportingSettings}>
                {supportings.map((supporting) => (
                  <a href={supporting.url} className='px-2' key={supporting.id}>
                    <img
                      src={supporting.image}
                      className='bg-white'
                      style={{ height: '200px', width: '100%', objectFit: 'contain' }}
                      alt={supporting.title}
                      title={supporting.title}
                    />
                  </a>
                ))}
              </Slider>
            </div> */}
          </section>

          {/* ----- Event Agenda ----- */}
          <section className="section">
            <h1 className="text-center section-title px-3 pb-5 mb-4">Event Agenda</h1>

            <table className="eventAgendaTable table table-bordered table-striped table-hover mx-auto shadow-sm">
              <thead>
                <tr className="text-white bg-primary">
                  <th className="text-center" style={{ fontSize: '20px' }}>Time</th>
                  <th style={{ fontSize: '20px' }}>Session</th>
                </tr>
              </thead>

              <tbody>
                {
                  eventAgendas.map((agenda, index) => (
                    <tr key={index}>
                      <td className="text-center px-3 px-sm-4 px-md-5" style={{ fontSize: '20px', minWidth: '200px' }}>
                        <div dangerouslySetInnerHTML={{ __html: agenda.time }}></div>
                      </td>
                      <td style={{ fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{ __html: agenda.session }}></div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>

            <div className="text-center mt-5">
              <Button className="px-3 py-2 rounded-3" color="primary" href="/registration/download-agenda" >
                <b className="m-0 text-600" style={{ color: "#fff", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>Download Agenda</b>
              </Button>
            </div>
          </section>

          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
