import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import Testimonials from "components/Testimonials.js";

import { Container, Row, Col, Button } from "reactstrap";



function ThankYou({ answer, match: { params: { id }, },}) {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={`THANK YOU ${answer}`} bgColor="#fff" />

      <div className="section profile-content" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col lg={12} className="text-center p-5 m-5">
              <div>
                {(id === "REPORT" || id === "EBOOK") && (
                  <h1>
                    Thank you for your interest in FutureSec Summit on
                    2025. The post-show report has been sent to
                    the email address provided, please check your inbox. In case
                    you do not receive the report, please drop us a line:
                    marketing@futuresecsummit.com
                  </h1>
                )}
                {id === "BROCHURE" && (
                  <div>
                    <h1>Thank you for showing your interest in FutureSec Summit on 2025.</h1>
                    <Button
                      className="mt-4 px-3 py-2 rounded-0"
                      color="primary"
                      href="/images/FuturesecBrochure.pdf"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", textTransform: 'none', letterSpacing: '1px' }}>
                        Download Event Brochure
                      </p>
                    </Button>                     
                    <p className="mt-4">In case you are unable to download the event brochure, please drop us a line: marketing@futuresecsummit.com</p>
                  </div>
                )}
                
                {id === "Agenda" && (
                  <div>
                    <h1>Thank you for showing your interest in FutureSec Summit on 2025.</h1>
                    <Button
                      className="mt-4 px-3 py-2 rounded-0"
                      color="primary"
                      href="/images/FuturesecAgenda.pdf"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", textTransform: 'none', letterSpacing: '1px' }}>
                        Download Event Agenda
                      </p>
                    </Button>                     
                    <p className="mt-4">In case you are unable to download the event Agenda, please drop us a line: marketing@futuresecsummit.com</p>
                  </div>
                )}
                {id === "REGISTER" && (
                  <h1>
                    Thank you for registering to attend FutureSec Summit DUBAI
                    UAE, to be held on 2025.
                  </h1>
                )}
                {(id === "SPONSOR" || id === "Sponsor") && (
                  <h1>
                    Thank you for your interest in FutureSec Summit. A
                    member of our team will be in touch with you for further
                    details about the event.
                  </h1>
                )}
                
                {id === "Speaker" && (
                  <h1>
                    Thank you for your interest in FutureSec Summit. A
                    member of our team will be in touch with you for further
                    details about the event.
                  </h1>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <Testimonials /> */}
      {/* <Sponsor sponsors={sponsors} /> */}
      <Footer />
    </>
  );
}

export default ThankYou;