import React from "react";

import { Container, Row, Col, Card, Modal } from "reactstrap";
import SpeakerModal from "components/SpeakerModal.js";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";



function SpeakerGrid({ speakers, speakersVIP }) {

  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  const [open, toggleOpen] = React.useState({
    open: false,
    speaker: {},
  });

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 8000,
    slidesToShow: 2,
    slidesToScroll: 2,
    className: "p-10",
  };

  const updateModal = (e, speaker) => {
    e.preventDefault();
    toggleOpen({ open: !open.open, speaker: speaker });
  };

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  function SpeakerCard({ speaker }) {
    return (
      <a href="/" onClick={(e) => updateModal(e, speaker)}>
        <Card
          className="card-background-image mx-auto p-3 text-white"
          style={{
            borderRadius: 0,
            boxShadow: "none",
            background: "transparent",
          }}
        >
          <div className='d-flex flex-column align-items-center'>
            <div className="speaker-image rounded-circle mb-3">
              <img src={speaker.image} className="speaker-image-img rounded-circle" alt="speaker" />
            </div>

            <div className="speakerName text-center">{speaker.name}</div>
            <div className="speakerTitle text-center">{speaker.title}</div>
            <div className="speakerCompany text-center">{speaker.company}</div>
          </div>
        </Card>
      </a>
    );
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "#0b233d",
        }}
        className="section"
        data-parallax={true}
      >
        <div className="filter" />
        <Container>
          <Row>
            <Col lg={12} className="">
              <h1 className="text-white text-center section-title mb-5">
                <b>SPEAKERS 2024</b>
              </h1>
            </Col>

                {speakersVIP && speakersVIP.length > 0 && speakersVIP.map((speaker, index) => (
                  <Col className="ml-auto mr-auto px-1" lg={3} md={3} key={index}>
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      duration={0.5}
                    >
                      <SpeakerCard speaker={speaker} />
                    </ScrollAnimation>
                  </Col>
                ))}

                <Col className="ml-auto mr-auto px-1" lg={12} md={12}></Col>

            {size ? (
              <>
                {speakers.map((speaker, index) => (
                  <Col className="ml-auto mr-auto px-1" lg={3} md={3} key={index}>
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      duration={0.5}
                    >
                      <SpeakerCard speaker={speaker} />
                    </ScrollAnimation>
                  </Col>
                ))}
              </>
            ) : (
              <Col xs={12} className={"mx-auto"}>
                <Slider {...settings}>
                  {speakers.map((speaker, index) => (
                    <SpeakerCard speaker={speaker} key={index} />
                  ))}
                </Slider>
              </Col>
            )}
          </Row>
          {/* <Row className="justify-content-center">
            <Col lg={3}>
              <Button href="/speakers" className="btn d-block py-2" color="info" size="sm" >
                <p className="m-0 text-700" style={{ color: "#fff" }}>VIEW ALL</p>
              </Button>
            </Col>
          </Row> */}
        </Container>

        <Modal isOpen={open.open} toggle={(e) => updateModal(e)} style={{}}>
          <div className="">
            <button aria-label="Close" className="close pt-3 pr-4" data-dismiss="modal" type="button" onClick={(e) => updateModal(e)} >
              <span aria-hidden={true}>×</span>
            </button>
            <SpeakerModal speaker={open.speaker} />
          </div>
        </Modal>
        {/* <br />
        <br />
        <Col lg={12} className="">
          <h1 className="text-dark text-center section-title">
            <b>ASK OUR SPEAKING PANEL ABOUT REMOTE WORK!</b>
          </h1>
        </Col>
        <iframe
          src="https://app.sli.do/event/pb6uxsbh"
          title="speakers"
          height="100%"
          width="100%"
          style={{ minHeight: "560px" }}
        ></iframe> */}
      </div>
    </>
  );
}

export default SpeakerGrid;
