// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth >= 1024
      ? true
      : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="section"
        style={{
          background: "transparent",
          paddingTop: "7rem",
        }}
        data-parallax={true}
      >
        {/* <Anime /> */}
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={12} xs={12} className={`${!size && "text-center"}`}>
              <div className="text-center">
                {/* <img src={require(`assets/images/logo/logo-3.png`)} className="" style={{ maxWidth: "400px" }} width="100%" alt="logo" /> */}
                <img src={`/FuturesecHeader.png`} className="" style={{ maxWidth: "500px" }} width="100%" alt="logo" />
              </div>

              <h1
                className="text-700 mt-4 text-center text-white"
                style={{
                  fontSize: size ? "2.25rem" : "1rem",
                }}
              >
                Biggest Cybersecurity Summit
              </h1>
              <h1
                className="text-700 mt-4 mb-3 text-center text-white"
                style={{ fontSize: size ? "2.25rem" : "1rem" }}
              >
                Edition 2025
              </h1>
            </Col>

            <Col md={12} xs={12} className="text-center mt-4" style={{ zIndex: 1000 }}>
              <Container fluid>
                <Row className="justify-content-center">
                  <Col lg={12} xs={12} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      // href="/register"
                      href="registration/as-speaker"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>
                        Register as Speaker
                      </p>
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="px-3 py-2 rounded-0"
                      // color="secondary"
                      href="registration/as-sponsor"
                      style={{ background: '#f5f5f5', borderColor: '#fff' }}
                    >
                      <p className="m-0 text-700" style={{ color: "#000", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>
                        Register as Sponsor
                      </p>
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      href="registration/as-delegate"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>
                        Register as Delegate
                      </p>
                    </Button>
                  </Col>
                  {/* <Col lg={4} xs={6} className={`px-${size ? 0 : 1}`}>
                    <Button
                      className="px-3 py-2 rounded-0"
                      color="primary"
                      style={{ width: "99%" }}
                      href="/sponsor/download-brochure?utm_source=BROCHURE"
                    >
                      <p className="m-0 text-700" style={{ color: "#fff" }}>
                        DOWNLOAD{size ? " " : <br />}BROCHURE
                      </p>
                    </Button>{" "}
                  </Col> */}
                  <Col lg={12} xs={12} className="d-none text-center mt-2">
                    <iframe
                      title="linkedin"
                      style={{ border: "none" }}
                      src="https://www.linkedin.com/pages-extensions/FollowCompany?id=14501034&counter=bottom"
                    ></iframe>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
