import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>{news.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              Implementations are expected to have a "huge impact" on reducing
              patient waiting times and improving patient experiences.
            </p>
            <br />
            <br />
            <p>
              Software bots powered by artificial intelligence (AI) will help
              improve patient care and automate end-to-end business processes at
              the Saudi German Hospital (SGH) during the Covid-19 crisis. The
              'digital workers' provided by Automation Anywhere are expected to
              transform SGH front and back offices to "modernise key touchpoints
              - from patient on-boarding to insurance billings, office
              management operations and other financial processes".
            </p>
            <br />
            <br />
            <p>
              The deployment was facilitated by Advansys ESC. "The addition of
              newest automation capabilities will allow the SGH to add value and
              support caregivers in the treatment process by providing deeper
              insights on the patient's specific condition, lifestyle and
              patient behaviour," said Dr Reem Osman, Group CEO, SGH. "The
              cognitive and AI technology infusion will empower our Intelligent
              Patient Platform to expand competencies and deliver better
              outcomes."
              <br />
              <br />
              The implementations are expected to have a "huge impact" on
              reducing patient waiting times and improving patient experiences.
              Automation Anywhere and Advansys ESC worked with SGH to identify
              automation opportunities in its insurance and finance departments
              that typically handle high-volume manual processes, such as
              insurance eligibility, insurance pre-authorisation and patient
              enrollment.
              <br />
              <br />
              "Intelligent automation empowers healthcare organisations to
              perform more time-consuming, manual tasks, freeing up staff to
              treat an increased number of patients by improving hospital
              efficiencies," said Milan Sheth, executive vice president, IMEA at
              Automation Anywhere. "Medical systems impacted by the global
              pandemic are seeing how automation technology can have a huge
              impact on a patient's progress by implementing bots for processes,
              such as simplifying patient appointment scheduling, streamlining
              account settlements and managing healthcare workflows. All of this
              time saved can go right back into patient care."
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
