import React from "react";


function SpeakerModal({ speaker }) {

  console.log('speaker : ', speaker);

  if (speaker && speaker.image) {
    return (
      <>
        <section className="speaker-popup border m-3 p-1">
          <div className={`d-flex flex-column flex-md-row align-items-center align-items-md-start`}>
            <div>
              <img src={speaker.image} className="speaker-popup-img border mb-4 mb-md-0" alt={speaker.name} />
            </div>

            <div className="text-center text-md-left">
              <div className="speaker-popup-name">{speaker.name}</div>
              <div className="speaker-popup-title">{speaker.title}</div>
              <div className="speaker-popup-company">{speaker.company}</div>

              <br />
            </div>
          </div>

          {speaker.bio && <hr className="hr " />}
          
          <div className="speaker-popup-bio" dangerouslySetInnerHTML={{ __html: speaker.bio }} />
        </section>

        {/* <Row>
          <Col className="" md="12">
            {speaker.text.map((t, index) => (
              <>
                <p
                  style={{ fontSize: "12px" }}
                  key={t}
                  dangerouslySetInnerHTML={{ __html: t }}
                />
                <br />
              </>
            ))}
          </Col>
        </Row> */}
      </>
    );
  } else return "";
}

export default SpeakerModal;
