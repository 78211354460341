import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";

import Footer from "components/Footer.js";

import { Redirect } from "react-router";

function Overview({ match }) {
  const { type } = match.params;

  if (
    !type ||
    ![
      "download-brochure",
      "download-agenda",
      "as-speaker",
      "as-sponsor",
      "as-delegate",
    ].includes(type)
  ) {
    return <Redirect to="/" />;
  }

  let title = "";
  let typeForm= "";

  switch(type) {
    case 'download-brochure':
        title = "Download Brochure";
        typeForm= "BROCHURE";
        break;
    case 'download-agenda':
        title = "Download Agenda";
        typeForm= "Agenda";
        break;
    case 'as-speaker':
        title = "Register as Speaker";
        typeForm= "Speaker";
        break;
    case 'as-sponsor':
        title = "Register as Sponsor";
        typeForm= "Sponsor";
        break;
    case 'as-delegate': default:
        typeForm= "REGISTER";
        title = "Register as Delegate";
        break;

  }

  return (
    <>
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
    <div className="section profile-content" style={{ padding: 0 }}>
        <Register type={typeForm} title={title} />
    </div>
      <Footer />
    </>
  );
}

export default Overview;
