import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import SpeakerCardAll from "components/SpeakerCardAll";
// import { speakers } from "./content";
import Footer from "components/Footer.js";
// import Sponsor from "components/Sponsor.js";
// import { sponsors } from "./content";
// import Testimonials from "components/Testimonials.js";

function SponsorPage() {
  const [speakers, setspeakers] = useState([])
  const [speakersVIP, setspeakersVIP] = useState([])
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/futuresec/data.json?v=3rdOct${new Date()}`, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        setspeakersVIP(data.speakersVIP);
        setspeakers(data.speakers);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="SPEAKERS 2024" bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <SpeakerCardAll speakers={speakers} speakersVIP={speakersVIP} />
      </div>
      {/* <Testimonials /> */}
      {/* <Sponsor sponsors={sponsors} /> */}
      <Footer />
    </>
  );
}

export default SponsorPage;
