import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import WhySponsor from "components/WhySponsor";
import Register from "components/Register";

import Footer from "components/Footer.js";
import { Redirect } from "react-router";
import NewsAll from "components/NewsAll";
import { news } from "./content";
import Testimonials from "components/Testimonials.js";
import Sponsor from "components/Sponsor.js";
import { sponsors } from "./content";

function Media({ match }) {
  const { type } = match.params;
  console.log(type);
  if (
    !type ||
    !["industry-news", "become-a-media-partner", "photo-gallery"].includes(type)
  ) {
    return <Redirect to="/media/industry-news" />;
  }
  let title = "";
  if (type === "industry-news") {
    title = "INDUSTRY NEWS";
  }
  if (type === "become-a-media-partner") {
    title = "BECOME A MEDIA PARTNER";
  }
  if (type === "photo-gallery") {
    title = "PHOTO GALLERY";
  }

  return (
    <>
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        {type === "industry-news" && <NewsAll news={news} />}
        {type === "become-a-media-partner" && (
          <Register type="MEDIA" title="" />
        )}
        {type === "photo-gallery" && <WhySponsor />}
      </div>
      <Testimonials />
      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default Media;
