import React from "react";
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
import AnchorLink from "react-anchor-link-smooth-scroll";



function NavbarDiv({ home }) {

  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [scroll, setScroll] = React.useState(home ? true : false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setScroll(false);
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setScroll(true);
      }
      if (!home) {
        setScroll(false);
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <Navbar
        className={`fixed-top ${scroll ? "navbar-transparent" : 'bg-primary'}`}
        style={{ minHeight: "50px" }}
        color-on-scroll="300"
        expand="lg"
      >
        <Container fluid>
          <div className="navbar-translate">
            <NavbarBrand data-placement="bottom" to="/" className="p-0" tag={Link} >
              {!scroll ? (
                <>
                  <img src={require(`assets/images/logo/logo-3.png`)} className="pr-2 border-right" width="100%" style={{ maxWidth: "125px" }} alt="logo" />
                </>
              ) : (
                <>
                  <img src={require(`assets/images/logo/logo-1.svg`)} className="" width="60px" style={{ maxWidth: "60px" }} alt="logo" />
                </>
              )}
            </NavbarBrand>
            <button
              aria-expanded={navbarCollapse}
              className={classnames(
                "navbar-toggler text-right navbar-toggler",
                {
                  toggled: navbarCollapse,
                }
              )}
              onClick={toggleNavbarCollapse}
            >
              <span className="navbar-toggler-bar bar1 bg-white-imp text-white" />
              <span className="navbar-toggler-bar bar2 bg-white-imp" />
              <span className="navbar-toggler-bar bar3 bg-white-imp" />
            </button>
          </div>
          <Collapse className="justify-content-end" navbar isOpen={navbarCollapse} >
            <Nav navbar>
              <NavItem className="d-flex align-middle">
                {social.map((s) => (
                  <a
                    key={s.name}
                    className={`btn-primary text-700 pt-4 px-2`}
                    style={{
                      color: scroll ? "white" : "white",
                      backgroundColor: "transparent",
                    }}
                    href={s.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className={`fa fa-${s.name}`}
                      style={{ fontSize: "1.5rem" }}
                    />
                  </a>
                ))}
              </NavItem>
              {menu.map((link, i) =>
                !link.items ? (
                  <NavItem key={i}>
                    {window.location.pathname === "/" && link.page === "/" ? (
                      <AnchorLink
                        offset="100"
                        href={`#${link.link}`}
                        onClick={toggleNavbarCollapse}
                        className={`nav-link navbar-item-custom text-700`}
                        style={{ color: scroll ? "white" : "white" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </AnchorLink>
                    ) : link.page ? (
                      <a
                        href={`/#${link.link}`}
                        className="nav-link navbar-item-custom text-700"
                        style={{ color: scroll ? "white" : "white" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    ) : (
                      <a
                        href={`${link.link}`}
                        className="nav-link navbar-item-custom text-700"
                        style={{ color: scroll ? "white" : "white" }}
                      >
                        {link.name}
                        <hr className="m-0 mt-1 p-0" align="center" />
                      </a>
                    )}
                  </NavItem>
                ) : (
                  <UncontrolledDropdown nav inNavbar key={i}>
                    <DropdownToggle
                      nav
                      caret
                      className="navbar-item-custom text-700"
                      style={{ color: scroll ? "white" : "white" }}
                    >
                      {link.name}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {link.items.map((i) => (
                        <DropdownItem
                          href={i.link}
                          key={i.link}
                          className="navbar-item-custom text-400"
                        >
                          {i.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                )
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarDiv;

const menu = [
  { name: "HOME", link: "/", page: undefined, items: undefined, grid: 1 },

  {
    name: "OVERVIEW",
    link: "",
    page: "/",
    items: [
      {
        name: "SHOW INFORMATION",
        link: "/overview/show-information",
      },
      { name: "THE ORGANIZER", link: "/overview/the-organiser" },
      { name: "CONTACT US", link: "/overview/contact-us" },
    ],
    grid: 1,
  },
  {
    name: "SPONSOR",
    link: "/sponsor/why-sponsor",
    page: undefined,
    items: undefined,
    // [
    //   {
    //     name: "WHY SPONSOR?",
    //     link: "/sponsor/why-sponsor",
    //   },
    // { name: "WHO ATTENDS?", link: "/sponsor/who-attends" },
    // {
    //   name: "DOWNLOAD BROCHURE",
    //   link: "/sponsor/download-brochure?utm_source=BROCHURE",
    // },
    // {
    //   name: "SPONSORSHIP ENQUIRY",
    //   link: "/sponsor/sponsorship-enquiry?utm_source=SPONSOR",
    // },
    // {
    //   name: "SPONSORS & PARTNERS",
    //   link: "/sponsor/sponsors-partners",
    // },
    // ],
    grid: 1,
  },
  // {
  //   name: "ATTEND",
  //   link: "/attend",
  //   page: undefined,
  //   items: [
  //     {
  //       name: "ATTEND FUTURESEC 2020",
  //       link: "/attend",
  //     },
  //     {
  //       name: "SPONSORS & PARTNERS",
  //       link: "/sponsor/sponsors-partners",
  //     },
  //   ],
  //   grid: 1,
  // },
  // {
  //   name: "AGENDA",
  //   link: "/agenda",
  //   page: undefined,
  //   items: undefined,
  //   grid: 1,
  // },
  {
    name: "REGISTER",
    link: "/register",
    page: undefined,
    items: [
      { name: "Register as Speaker", link: "/registration/as-speaker" },
      { name: "Register as Sponsor", link: "/registration/as-sponsor" },
      { name: "Register as Delegate", link: "/registration/as-delegate" },
    ],
    grid: 1,
  },
  {
    name: "SPEAKERS",
    link: "/speakers",
    page: undefined,
    items: undefined,
    grid: 1,
  },
  // {
  //   name: "CISO POWERLIST",
  //   link: "/powerlist",
  //   page: undefined,
  //   items: undefined,
  //   grid: 1,
  // },
  // {
  //   name: "MEDIA",
  //   link: "",
  //   page: "/",
  //   items: [
  //     {
  //       name: "INDUSTRY NEWS",
  //       link: "/media/industry-news",
  //     },
  //     {
  //       name: "BECOME A MEDIA PARTNER",
  //       link: "/media/become-a-media-partner?utm_source=MEDIA",
  //     },
  //   ],
  //   grid: 1,
  // },
];

const social = [
  // {
  //   name: "facebook",
  //   link: "https://www.facebook.com/KTRemoteWorkforceSummit/",
  // },
  {
    name: "linkedin",
    // link: "https://www.linkedin.com/showcase/futuresecksa/ ",
    link: "https://www.linkedin.com/showcase/khaleej-times-events/",
  },
  // {
  //   name: "instagram",
  //   link: "https://www.instagram.com/remoteworkforcesummit/",
  // },
];
