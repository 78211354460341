import React from "react";
import { Row, Container, Col, Button } from "reactstrap";

function DemoFooter() {
  return (
    <footer
      className="footer pt-5 py-3"
      style={{
        backgroundColor: "#f3faff",
        backgroundImage: "linear-gradient(to bottom, #fff, #e2f2ff)",
      }}
    >
      <Container fluid>
        <Row className="justify-content-center py-5">
          <Col lg={4} className="py-2 text-center">
            <h3>Organised By</h3>
            <a href="https://www.khaleejtimes.com/">
              <img
                src={require(`assets/images/logo/khaleejdark-1.png`)}
                style={{ maxWidth: "300px" }}
                width="100%"
                alt="Finnovex"
              />
            </a>
          </Col>
          <Col lg={4} className="text-center py-2 align-self-center">
            <h3 className="d-inline">
              <a href="https://www.khaleejtimes.com/privacy-notice">
                Privacy Notice
              </a>
            </h3>
            {"  |  "}
            <h3 className="d-inline">
              <a href="/overview/contact-us">
                Contact
              </a>
            </h3>
            {"  |  "}
            <h3 className="d-inline">
              <a href="/sponsor/download-brochure">
                Brochure
              </a>
            </h3>
          </Col>

          <Col lg={4} className="text-center align-self-center">
            <h3 className="mb-0">Connect With Us</h3>
            {/* <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ color: "#1d1960" }}
              href="https://www.facebook.com/KTRemoteWorkforceSummit/"
            >
              <i className="fa fa-facebook" />
            </Button> */}
            <Button
              className="btn-neutral btn-just-icon mr-1"
              style={{ color: "#1d1960" }}
              onClick={() => window.open("https://www.linkedin.com/showcase/khaleej-times-events/", "_blank")}
            >
              <i className="fa fa-linkedin" />
            </Button>
            {/* <Button
              className="btn-neutral btn-just-icon"
              style={{ color: "#1d1960" }}
              href="https://www.instagram.com/remoteworkforcesummit/"
            >
              <i className="fa fa-instagram" />
            </Button> */}
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} className={"text-center"}>
            <p>
              ©2024 Galadari Printing and Publishing LLC. All rights reserved.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
