import React from "react";
import { Container, Row, Col } from "reactstrap";
import CountUp from "react-countup";



function TextGrid() {

  let pageHeader = React.createRef();

  const size =
    document.body.clientWidth >= 1024 ||
    document.documentElement.clientWidth > 1024
      ? true
      : false;

  return (
    <>
      <div
        style={{
          backgroundColor: "#016cad",
        }}
        className="py-2 section"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container>
          <Row className="justify-content-center">
            {content.map((c, i) => (
              <Col xs={12} sm={4} key={i} className="text-center pt-2 text-white mb-3">
                <h1
                  className="text-700 d-inline mt-0"
                  style={{ fontSize: size ? "3rem" : "2rem" }}
                >
                  <CountUp end={c.value} duration={3} />
                  {c.plus && "+"}
                </h1>
                <h1
                  className="text-400 mt-0"
                  style={{ fontSize: size ? "1.5rem" : "1rem" }}
                >
                  {c.title}
                </h1>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default TextGrid;

const content = [
  { title: "DELEGATES", value:  300, image: 32, plus: true },
  { title: "EXPERT SPEAKERS", value: 30, image: 33, plus: true },
  // { title: "HOURS OF LEARNING", value: 8, image: 34, plus: true },
  { title: "DAY", value: 1, image: 34, plus: false },
];
