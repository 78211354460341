import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

function news({ news }) {
  const shareUrl = "https://remoteworkforcesummit.com/news";
  return (
    <>
      <Container justify-contents-center="true">
        <div className="owner">
          <div className="avatar-big text-center">
            <img
              alt=""
              className="img img-no-padding img-responsive mt-5"
              src={require(`assets/news/${news.image}`)}
              style={{ maxwidth: "100%" }}
            />
          </div>
        </div>
        <Row>
          <Col className={"ml-auto mr-auto mt-4"} md="10">
            <h1>
              5 reasons to look forward to the Artelligence Forum – Virtual
              edition this year.
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={1} className="d-none d-xl-block d-lg-block d-md-block"></Col>
          <FacebookShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                  marginLeft: "15px",
                }}
              >
                <span style={{ color: "#3b5998", display: "inline" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-facebook-square float-left"
                  />
                </span>
              </Button>
            }
          />

          <LinkedinShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#0976b4" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-linkedin-square float-left"
                  />
                </span>
              </Button>
            }
          />
          <TwitterShareButton
            url={`${shareUrl}${news.link}`}
            style={{ padding: 0, margin: 0, display: "inline !important" }}
            children={
              <Button
                color="neutral"
                outline
                style={{
                  padding: "0rem",
                  marginRight: "30px",
                }}
              >
                <span style={{ color: "#55acee" }}>
                  <i
                    style={{ fontSize: "50px" }}
                    className="fa fa-twitter-square float-left"
                  />
                </span>
              </Button>
            }
          />
        </Row>

        <Row>
          <Col className={"ml-auto mr-auto"} md="10">
            <p>
              The scope of AI in business transformation is constantly growing,
              and we see no signs of it coming to a halt anytime soon.
              Artificial Intelligence is becoming an imperative for businesses
              that want to maintain a competitive edge. We know that any
              organization failing to capitalize on AI might not be able to stay
              relevant or competitive in the market.
              <br />
              Khaleej Times’s Artelligence Forum serves as the 1st of its kind
              summit, dedicated to AI in the GCC region. The Forum aims at
              transforming businesses by providing access to actionable
              strategies and tactics which help in implementing AI successfully.
              The virtual edition of the Artelligence Forum proves to be a
              platform where businesses gain insights on AI that can be utilized
              positively to create a better future.
              <br />
            </p>
            <br />
            <br />
            <h3>2020 Edition - Topics covered</h3>
            <p>
              The 2020 edition of the Artelligence Forum, pushes the envelope
              further to help businesses transform through the implementation of
              AI. The topics that will be touched upon will range from Cloud
              Computing & AI, Automation, Machine Learning to Ethics of AI and
              its Regulations and Policies. Visit our website for more details.
            </p>
            <br />
            <br />
            <h3>A comprehensive agenda</h3>
            <p>
              The Artelligence Forum looks at exploring specifically how AI is
              affecting the strategy execution and development in organizations.
              The extensive agenda panning over 2 days covers keynotes, case
              studies, workshops, roundtables, live Q&A’s and polling. Panel
              discussions will emphasise on improving awareness about AI and its
              implementation so as to attain business goals.
            </p>
            <br />
            <br />
            <h3>Esteemed panelists</h3>
            <p>
              The forum will feature an esteemed body of panelists, individuals
              with the highest understanding of AI. They will be seen discussing
              in-depth, emerging technologies, trends, usage and implementation
              of AI. Panelists will share key success stories and complex
              business problems, resolved using AI with a combination of tools
              and technologies. Visit the website artelligenceforum.com to know
              more about the panelists.
            </p>
            <br />
            <br />
            <h3>Ultimate Conference Experience-Virtually</h3>
            <p>
              With the Artelligence Forum, the definition of a virtual
              conference takes a new meaning. The Forum in its 1st edition
              facilitated the rise of AI in businesses and government bodies and
              aims to continue doing so, this time ‘Virtually’! Live
              presentations, hands-on workshops, on demand sessions and much
              more. The virtual edition will serve all purposes of a conference
              and more.
            </p>
            <br />
            <br />
            <h3>Networking Opportunities</h3>
            <p>
              The Artelligence Forum presents a premium networking opportunity
              to all attendees. The attendees can develop their network through
              1-1 virtual meetings, chats and roundtable discussions at the
              Networking Lounge. This is a perfect platform to create meaningful
              & profitable connections which serve the attendees professionally
              and personally.
            </p>
            <br />
            <br />
            <p>
              Don’t miss out! For registrations and more details, visit
              artelligenceforum.com
            </p>
            <br />
            <br />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default news;
