import React from "react";
import { Container, Row, Col, Card } from "reactstrap";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Navbar from "components/Navbar.js";

import Hr from "./news/Hr";
import Hyperloop from "./news/Hyperloop";
import Advancements from "./news/Advancements";
import AIbots from "./news/AIbots";
import Bigdata from "./news/Bigdata";
import Coop from "./news/Coop";
import Transform from "./news/Transform";
import Cyber from "./news/Cyber";
import Matthias from "./news/Matthias";
import Alexey from "./news/Alexey";
import Reason from "./news/Reason";
import Step from "./news/Step";

import Footer from "components/Footer.js";
import { news } from "./content";
import Sponsor from "components/Sponsor.js";
import { sponsors } from "./content";

function NewsSingle(props) {
  const News = props.match.params.news;
  const n = news.find((x) => x.link === News);
  return (
    <>
      <Navbar />

      <ProfilePageHeader title="INDUSTRY NEWS" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Container fluid>
          <Row>
            <Col
              lg={9}
              xs={12}
              style={{
                padding: 0,
              }}
            >
              {n.id === "Hr" ? <Hr news={n} /> : ""}
              {n.id === "Hyperloop" ? <Hyperloop news={n} /> : ""}
              {n.id === "Advancements" ? <Advancements news={n} /> : ""}
              {n.id === "AIbots" ? <AIbots news={n} /> : ""}
              {n.id === "Bigdata" ? <Bigdata news={n} /> : ""}
              {n.id === "Coop" ? <Coop news={n} /> : ""}
              {n.id === "Transform" ? <Transform news={n} /> : ""}
              {n.id === "Cyber" ? <Cyber news={n} /> : ""}
              {n.id === "Matthias" ? <Matthias news={n} /> : ""}
              {n.id === "Alexey" ? <Alexey news={n} /> : ""}
              {n.id === "Reason" ? <Reason news={n} /> : ""}
              {n.id === "Step" ? <Step news={n} /> : ""}
            </Col>
            <Col lg={3} xs={12} style={{ backgroundColor: "#1d1960" }}>
              <h2 className="text-center text-white text-uppercase pt-4">
                <b>Popular Posts</b>
              </h2>
              <br />
              <Container fluid>
                <Row>
                  {news.map((n, index) => (
                    <Col
                      xs={12}
                      style={{
                        padding: 0,
                      }}
                      key={index}
                    >
                      <Card
                        style={{
                          borderRadius: 0,
                          width: "100%",
                          backgroundColor: "#fff",
                        }}
                      >
                        <a href={`/media/industry-news/${n.link}`}>
                          <Container fluid>
                            <Row>
                              <Col xs="6" style={{ padding: 0 }}>
                                <img
                                  alt=""
                                  className="img-rounded img-no-padding img-responsive"
                                  src={require(`assets/news/${n.image}`)}
                                  style={{
                                    borderRadius: 0,
                                    padding: "10px",
                                  }}
                                  height="100%"
                                />
                              </Col>
                              <Col xs="6">
                                <h6
                                  className="text-left text-dark"
                                  style={{ marginTop: "8px", padding: 0 }}
                                >
                                  {n.title}
                                </h6>
                              </Col>
                            </Row>
                          </Container>
                        </a>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>

      <Sponsor sponsors={sponsors} />
      <Footer />
    </>
  );
}

export default NewsSingle;
